import React, { useEffect, useState } from 'react';
import { Card, CardContent, Grid, Paper, Typography } from '@mui/material';
import {
  InsertDriveFile as FileIcon,
  Description as DocumentIcon,
  Image as ImageIcon,
  MusicNote as AudioIcon,
  Videocam as VideoIcon,
  Delete,
} from '@mui/icons-material';

import { FileListProps } from '../interface/files';

import { ProcessProps } from '../interface/process';
import { AssetType } from '../interface/asset';
import AssetService from '../services/asset.service';
import { Link as RouterLink } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';

const getIconByExtension = (extension: string) => {
  const iconMapping: { [key: string]: JSX.Element } = {
    doc: <DocumentIcon fontSize='large' />,
    docx: <DocumentIcon fontSize='large' />,
    jpg: <ImageIcon fontSize='large' />,
    jpeg: <ImageIcon fontSize='large' />,
    png: <ImageIcon fontSize='large' />,
    gif: <ImageIcon fontSize='large' />,
    mp3: <AudioIcon fontSize='large' />,
    mp4: <VideoIcon fontSize='large' />,
  };

  const defaultIcon = <FileIcon fontSize='large' />;
  return iconMapping[extension.toLowerCase()] || defaultIcon;
};

const getFileName = (path: string): string => {
  const parts = path.split(/[/\\]/);  
  return parts[parts.length - 1];
}

const ProcessAssetList: React.FC<ProcessProps> = ({ process }) => {
  const [assets, setAssets] = useState<AssetType[]>([]);

  const fetchAssets = () => {
    AssetService.getAssetsForProcess(process.id)
      .then((response) => {
        setAssets(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchAssets();
  }, []);

  return (
    <Grid container spacing={2}>
      {assets.map((a, index) => (
        <Grid item key={index}>
          {getIconByExtension(a.file.substring(a.file.lastIndexOf('.') + 1))}
            <MuiLink
              color='primary'
              component={RouterLink}
              to={`/assets/${a.id}`}
              underline='none'
            >
 
          <Typography variant='body2' align='center'>
          {getFileName(a.file)}
          </Typography>

            </MuiLink>
        </Grid>
      ))}
    </Grid>
  );
};

export default ProcessAssetList;
