import axios from 'axios';
import authHeader from './auth-header';

const axiosBackend = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL,
});

class NotificationService {
  fetchNotifications() {
    return axios.get(axiosBackend.defaults.baseURL + 'notifications/', {
      headers: authHeader(),
    });
  }

  fetchNotificationRules() {
    return axios.get(axiosBackend.defaults.baseURL + 'notification-rules/', {
      headers: authHeader(),
    });
  }

}

export default new NotificationService();
