import React, { forwardRef, useRef, useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import Editor from 'react-markdown-editor-lite';
import { Box, Typography } from '@mui/material';
import 'react-markdown-editor-lite/lib/index.css';
import MarkdownIt from 'markdown-it';
import { EditorIssue } from './EditorIssuePlugin';

interface MarkdownEditorProps {
  initialValue: string;
  onChange: (value: string) => void;
  url?: string;
  document?: string;
}

const mdParser = new MarkdownIt(/* Markdown-it options */);

/*
Editor.use(MyCounterA, {
  start: 2,
});
*/

const DocumentEditor: React.FC<MarkdownEditorProps> = ({
  initialValue,
  onChange,
  url,
  document,
}) => {
  const [content, setContent] = useState<string>(initialValue);
  const editorRef = useRef(null);

  const handleEditorChange = (data: { text: string }) => {
    setContent(data.text);
    onChange(data.text);
    //console.log(data.text)
  };

  Editor.use(EditorIssue, {
    start: 2,
    document: document,
  });

  useEffect(() => {
    if (url) {
      fetch(url)
        .then((response) => response.text())
        .then((data) => {
          setContent(data);
          onChange(data);
        })
        .catch((error) => {
          console.error('Error fetching data from URL:', error);
        });
    }
  }, [url, onChange]);

  return (
    <Box>
      <Editor
        ref={editorRef}
        value={content}
        style={{
          height: '500px',
        }}
        view={{ menu: true, md: true, html: true }}
        onChange={handleEditorChange}
        //renderHTML={(text) => <ReactMarkdown children={text} />}
        renderHTML={(text) => mdParser.render(text)}
        plugins={[
          'header',
          'font-bold',
          'font-italic',
          'font-underline',
          'font-strikethrough',
          'list-unordered',
          'list-ordered',
          'block-quote',
          'block-wrap',
          'block-code-inline',
          'block-code-block',
          'table',
          'image',
          'link',
          'clear',
          'logger',
          'mode-toggle',
          'tab-insert',
          'hercules-issue-create',
        ]}
      />
    </Box>
  );
};

export default DocumentEditor;
