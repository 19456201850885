import * as React from 'react';
import { useEffect } from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import NotificationService from '../services/notification.service';
import Title from './Title';

import { NotificationType, NotificationsType} from '../interface/notification';


export default function NotificationList() {
  const [notifications, setNotifications] = React.useState<NotificationsType>(
    []
  );

  const fetchInvitations = () => {
    NotificationService.fetchNotifications()
      .then((response) => {
        setNotifications(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchInvitations();
  }, []);

  return (
    <Grid container spacing={3}>
      {notifications.map((notification) => (
        <Grid item key={notification.id} xs={12} sm={12} md={12}>
          <Card>
            <CardContent>
              <Title>{notification.id}</Title>
              <Typography color='textSecondary' gutterBottom>
                User: {notification.recipient}
              </Typography>
              <Typography color='textSecondary' gutterBottom>
                Text: {notification.verb}
              </Typography>
              <Typography color='textSecondary' gutterBottom>
                ContentType: {notification.target_content_type}
              </Typography>
 
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
