import React, { useEffect, useState } from 'react';
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';

import { useLocation } from 'react-router-dom';
import { Card, CardContent, Grid } from '@mui/material';
import { Mention } from '../interface/mention';
import MentionService from '../services/mention.service';
import { Link as RouterLink } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';

import Title from './Title';

interface User {
  id: string;
  username: string;
}

const MentionDetail: React.FC = () => {
  const location = useLocation();
  const [mention, setMention] = useState<Mention>();
  const [mentionId, setMentionId] = useState<string>('');

  const fetchMention = (id: string) => {
    MentionService.getMention(id)
      .then((response) => {
        setMention(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const id = location.pathname.split('/').pop();
    if (id) {
      setMentionId(id);
      fetchMention(id);
    }
  }, []);

  return (
    <>
      {mention ? (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <Title>{mention.id}</Title>
          </Grid>
          <Grid item key={mentionId} xs={12} sm={12} md={12}>
            <Card>
              <CardContent>
                <ListItemText primary={`ID: ${mention.id}`} />
             </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
          <Typography>Test</Typography>
          </Grid>


        </Grid>
      ) : (
        <Typography variant='h5' component='div'>
          Loading ..
        </Typography>
      )}
    </>
  );
};

export default MentionDetail;
