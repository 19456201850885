import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import VerifiedIcon from '@mui/icons-material/Verified';
import NoteIcon from '@mui/icons-material/Note';
import { DocumentType } from '../interface/document';
import { DocumentIssueInputType } from '../interface/document';
import AddIcon from '@mui/icons-material/Add';
import IssueService from '../services/issue.service';
import { useNavigate } from 'react-router-dom';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import GroupIcon from '@mui/icons-material/Group';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import LockIcon from '@mui/icons-material/Lock';
import SelectUsers from './SelectUsers';
import Badge from '@mui/material/Badge';
import PlusDocumentIssue from './PlusDocumentIssue';
import TaskIcon from '@mui/icons-material/Task';

interface DocumentIssueInputProps {
  document: DocumentType;
}
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const handleAdminClick = () => {
  console.log('Admin button clicked');
};

const handleShareClick = () => {
  console.log('Share button clicked');
};

const handleNoteClick = () => {
  console.log('Note button clicked');
};

const handleVerifiedClick = () => {
  console.log('Verified button clicked');
};

const handleForkRightClick = () => {
  console.log('Fork Right button clicked');
};

export default function DocumentPanel(props: DocumentIssueInputProps) {
  const { document } = props;
  const navigate = useNavigate();
  const [showSelectUsers, setShowSelectUsers] = useState(false);

  const [inputValue, setInputValue] = React.useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleOpenSelectUsers = () => {
    setShowSelectUsers(true);
  };

  const handleCloseSelectUsers = () => {
    setShowSelectUsers(false);
  };

  /*
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13) {
      handleSendClick(inputValue);
    }
  };
  */

  /*
  const handleSendClick = (value: string) => {
    const issue: DocumentIssueInputType = {
      document: document.id,
      name: value,
      timestamp: new Date(),
    };
    IssueService.postDocumentIssue(issue);
    setInputValue('');
    navigate(`/documents/${document.id}/issues`);
  };
  */

  return (
    <Card>
      <SelectUsers
        open={showSelectUsers}
        onClose={handleCloseSelectUsers}
      ></SelectUsers>
      <CardContent>
        <Typography> {document.name}</Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label='Admin' onClick={handleAdminClick}>
          <AdminPanelSettingsIcon></AdminPanelSettingsIcon>
        </IconButton>
        <IconButton aria-label='Group' onClick={handleOpenSelectUsers}>
          <GroupIcon></GroupIcon>
        </IconButton>
        <IconButton aria-label='Note' onClick={handleNoteClick}>
          <Badge badgeContent={4} color='primary'>
            <NoteIcon></NoteIcon>
          </Badge>
        </IconButton>
        <IconButton aria-label='Schedule' onClick={handleForkRightClick}>
          <ScheduleIcon></ScheduleIcon>
        </IconButton>
        <IconButton aria-label='Verified' onClick={handleVerifiedClick}>
          <VerifiedIcon></VerifiedIcon>
        </IconButton>
        <IconButton component={RouterLink} 
            to={`/tasks/${document.task}`}
        >
        <TaskIcon></TaskIcon>
        </IconButton>

        <ExpandMore expand={true} aria-expanded={true} aria-label='Add Issue'>
          <MuiLink
            color='primary'
            component={RouterLink}
            to={`/documents/${document.id}/add`}
            underline='none'
          >
            <AddIcon></AddIcon>
          </MuiLink>
          <MuiLink
            color='primary'
            component={RouterLink}
            to={`/documents/${document.id}/add`}
            underline='none'
          >
            <SaveIcon></SaveIcon>
          </MuiLink>
          <MuiLink
            color='primary'
            component={RouterLink}
            to={`/documents/${document.id}/add`}
            underline='none'
          >
            <LockIcon></LockIcon>
          </MuiLink>
        </ExpandMore>
      </CardActions>
    </Card>
  );
}
