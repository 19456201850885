import React, { useEffect, useState } from 'react';
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';

import { useLocation } from 'react-router-dom';
import { Card, CardContent, Grid } from '@mui/material';
import { Signature } from '../interface/signature';
import SignatureService from '../services/signature.service';
import { Link as RouterLink } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';

import Title from './Title';

interface User {
  id: string;
  username: string;
}

const SignatureDetail: React.FC = () => {
  const location = useLocation();
  const [signature, setSignature] = useState<Signature>();
  const [signatureId, setSignatureId] = useState<string>('');

  const fetchSignature = (id: string) => {
    SignatureService.getSignature(id)
      .then((response) => {
        setSignature(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const id = location.pathname.split('/').pop();
    if (id) {
      setSignatureId(id);
      fetchSignature(id);
    }
  }, []);

  return (
    <>
      {signature ? (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <Title>{signature.user}</Title>
          </Grid>
          <Grid item key={signatureId} xs={12} sm={12} md={12}>
            <Card>
              <CardContent>
                <ListItemText primary={`ID: ${signature.id}`} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Typography>{signature.timestamp}</Typography>
          </Grid>
        </Grid>
      ) : (
        <Typography variant='h5' component='div'>
          Loading ..
        </Typography>
      )}
    </>
  );
};

export default SignatureDetail;
