import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link as MuiLink, Typography } from '@mui/material';

import { ProcessType, ProcessesType } from '../interface/process';
import { AssetsType } from '../interface/asset';
import AssetService from '../services/asset.service';

import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@mui/material';

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

interface ProcessProps {
  process: ProcessType;
}

export default function ProcessAssets(props: ProcessProps) {
  const { process } = props;
  const [assets, setAssets] = useState<AssetsType>([]);

  function getLastPathComponent(url: string): string | null {
    const parsedUrl = new URL(url);
    const pathComponents = parsedUrl.pathname.split('/');
    const lastPathComponent = pathComponents[pathComponents.length - 1];

    return lastPathComponent || null;
  }

  const fetchAssets = (id: string) => {
    AssetService.getAssetsForProcess(id)
      .then((response) => {
        console.log(response.data);
        setAssets(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (process) {
      fetchAssets(process.id);
    }
  }, []);

  return (
    <>
      {assets.length ? (
        <List>
          {assets.map((asset) => (
            <ListItem
              key={asset.id}
              button
              onClick={() => {
                console.log('File selected:', asset.file);
              }}
            >
              <MuiLink href={asset.file} target='_blank' rel='noopener'>
                <ListItemText primary={getLastPathComponent(asset.file)} />
              </MuiLink>
              <ListItemSecondaryAction></ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      ) : (
        <></>
      )}
    </>
  );
}
