import * as React from 'react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Solution from './Solution';
import { ProcessesType } from '../interface/process';
import { IssueType } from '../interface/issue';
import IssueService from '../services/issue.service';

interface IssueProps {
  issue: IssueType;
}

export default function SolutionsList(props: IssueProps) {
  const { issue } = props;

  const [processes, setProcesses] = useState<ProcessesType>([]);

  const handleSolutionUpdate = (newData: string) => {
    console.log(newData);
    fetchSolutions(issue.id);
  };
  const fetchSolutions = (id: string) => {
    IssueService.getIssueSolutions(id)
      .then((response) => {
        console.log(response.data.results);
        setProcesses(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (issue) {
      fetchSolutions(issue.id);
    }
  }, [issue]);

  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      {processes.map((process) => (
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Solution process={process} onUpdate={handleSolutionUpdate} />
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
}
