import React, { useState } from 'react';
import FileList from './FileList';
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@mui/material';
import { Delete, Send, Attachment, Verified, Note } from '@mui/icons-material';

import CardActions from '@mui/material/CardActions';

import ProcessService from '../services/process.service';
import { useNavigate } from 'react-router-dom';
import AssetService from '../services/asset.service';

import {
  ProcessInputProps,
  ProcessForm,
  ProcessInputType,
} from '../interface/process';

const ProcessInputForm: React.FC<ProcessInputProps> = ({ issue }) => {
  const [text, setText] = useState('');
  const [files, setFiles] = useState<File[]>([]);
  const [status, setStatus] = useState(1);
  const [sanity, setSanity] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [inputKey, setInputKey] = useState(Date.now());
  const navigate = useNavigate();

  const handleFieldChange =
    (field: keyof ProcessForm) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (field === 'files' && event.target.files) {
        const selectedFiles = Array.from(event.target.files).slice(0, 5);
        const merger = [...files, ...selectedFiles];
        const merged: File[] = Array.from(
          new Map(merger.map((file) => [file.name, file])).values()
        );
        setFiles(merged);
        setExpanded(true);
      } else {
        if (field === 'text') {
          setText(event.target.value);
        }
      }
    };

  const handleFileRemove = (index: number) => {
    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
    setInputKey(Date.now());
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13) {
      handleSendClick(text);
    }
  };

  const handleSendClick = async (value: string) => {
    const convertedArray = files.map((file) => {
      return {
        name: file.name,
        type: file.type,
        size: file.size,
      };
    });

    console.log(files);
    const conversion = JSON.stringify({ text, convertedArray, status, sanity });
    console.log(conversion);
    //return;

    const process: ProcessInputType = {
      issue: issue.id,
      text: value,
      timestamp: new Date(),
    };

    const p = await ProcessService.postNewProcess(process);

    //const pid = 'e4dfa8f4-11d4-4f57-99e9-4164edf84b10';
    const pid = p.id;
    AssetService.postAsset(pid, conversion, true);

    files.forEach((file, index) => {
      AssetService.postRawAsset(pid, file, false);
    });

    navigate(`/issues/${issue.id}`);
    setFiles([]);
    setText('');
    return;
  };

  const handleSubmit = async () => {
    const convertedArray = files.map((file) => {
      return {
        name: file.name,
        type: file.type,
        size: file.size,
      };
    });

    const conversion = JSON.stringify({ text, convertedArray, status, sanity });
    //console.log(conversion);
    return;
    try {
      const formData = new FormData();
      formData.append('text', text);
      formData.append('fileCount', String(files.length));
      for (let i = 0; i < files.length; i++) {
        formData.append('file', files[i]);
      }
      formData.append('status', String(status));
      formData.append('sanity', String(sanity));

      const response = await fetch('API_ENDPOINT', {
        method: 'POST',
        body: formData,
      });

      // Handle response
      if (response.ok) {
        console.log('Files uploaded successfully!');
      } else {
        console.error('Failed to upload files:', response.statusText);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const handleDiamondClick = () => {
    console.log('Diamond button clicked');
  };

  const handleShareClick = () => {
    console.log('Share button clicked');
  };

  const handleNoteClick = () => {
    console.log('Note button clicked');
  };

  const handleVerifiedClick = () => {
    console.log('Verified button clicked');
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const toggleExpand = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <Grid container spacing={2} justifyContent='center'>
      <Grid item xs={12} sm={12} md={12}>
        <Card>
          <CardContent>
            <form>
              <TextField
                fullWidth
                placeholder='Add Process'
                variant='outlined'
                value={text}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                InputProps={{
                  disableUnderline: true,
                  sx: { fontSize: 'default' },
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='send'
                        onClick={() => handleSendClick(text)}
                      >
                        <Send />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </form>
          </CardContent>
          <CardActions disableSpacing>
            <IconButton aria-label='add to favorites' onClick={handleNoteClick}>
              <Note />
            </IconButton>
            <IconButton
              aria-label='add to favorites'
              onClick={handleVerifiedClick}
            >
              <Verified />
            </IconButton>
            <input
              type='file'
              accept='.jpg,.jpeg,.png,.pdf, .doc, .docx, .xlsx, .xls'
              multiple
              onChange={handleFieldChange('files')}
              style={{ display: 'none' }}
              id='upload-file'
              key={inputKey}
            />
            <label htmlFor='upload-file'>
              <IconButton aria-label='upload files' component='span'>
                <Attachment />
              </IconButton>
            </label>
          </CardActions>

          {/* expanded && (
            <CardMedia>
              <FileList files={files}></FileList>
            </CardMedia>
          )*/}
          {expanded && (
            <CardMedia>
              <List>
                {files.map((file, index) => (
                  <ListItem
                    key={index}
                    button
                    onClick={() => {
                      console.log('File selected:', file.name);
                    }}
                  >
                    <ListItemText primary={file.name} />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge='end'
                        aria-label='delete'
                        onClick={() => handleFileRemove(index)}
                      >
                        <Delete />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </CardMedia>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default ProcessInputForm;
