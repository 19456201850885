import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';

import { Link as RouterLink } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

import { useLocation } from 'react-router-dom';
import IssueService from '../services/issue.service';
import { IssueType } from '../interface/issue';
import { ProcessesType } from '../interface/process';
import Process from './Process';
import ProcessInput from './ProcessInput';
import ProcessInForm from './ProcessInForm';
import ProcessInputForm from './ProcessInputForm';

const Issue: React.FC = () => {
  const location = useLocation();
  const [issueData, setIssueData] = useState<IssueType>();
  const [processes, setProcesses] = useState<ProcessesType>([]);
  const [issueId, setIssueId] = useState<string>('');

  const fetchIssue = (id: string) => {
    IssueService.getIssue(id)
      .then((response) => {
        setIssueData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchProcesses = (id: string) => {
    IssueService.getProcesses(id)
      .then((response) => {
        console.log(response.data.results);
        setProcesses(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteClick = (process: ProcessesType) => {
    //ProcessService.deleteProcess(process);
    console.log('Will delete: ', process);
  };

  useEffect(() => {
    const id = location.pathname.split('/').pop();
    if (id) {
      setIssueId(id);
      fetchIssue(id);
      fetchProcesses(id);
    }
  }, [location]);

  return (
    <Grid container spacing={3}>
      <Grid item key={issueId} xs={12} sm={12} md={12}>
        <Card>
          {issueData ? (
            <CardContent>
              <Typography variant='h5' component='div'>
                {issueData.name}
              </Typography>
              <Typography variant='subtitle1' color='text.secondary'>
                {issueData.url}
              </Typography>
              <Typography variant='subtitle1' color='text.secondary'>
                <MuiLink
                  color='primary'
                  component={RouterLink}
                  to={`/documents/${issueData.document}`}
                  underline='none'
                >
                  <Typography
                    variant='h6'
                    gutterBottom
                    sx={{
                      fontWeight: 600,
                    }}
                  >
                    {issueData.document}
                  </Typography>
                </MuiLink>
              </Typography>
              <Typography variant='subtitle1' color='text.secondary'>
                <MuiLink
                  color='primary'
                  component={RouterLink}
                  to={`/processes/${issueData.process}`}
                  underline='none'
                >
                  <Typography
                    variant='h6'
                    gutterBottom
                    sx={{
                      fontWeight: 600,
                    }}
                  >
                    {issueData.process}
                  </Typography>
                </MuiLink>
              </Typography>
            </CardContent>
          ) : (
            <Typography variant='h5' component='div'>
              Loading ..
            </Typography>
          )}
        </Card>
      </Grid>

      {issueData && (
        <Grid item xs={12} sm={12} md={12}>
          <ProcessInputForm issue={issueData} />
        </Grid>
      )}
      {processes.map((process) => (
        <Grid item key={process.id} xs={12} sm={12} md={12}>
          <Process process={process} />
        </Grid>
      ))}
    </Grid>
  );
};

export default Issue;
