import React, { useEffect, useState } from 'react';
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';

import { useLocation } from 'react-router-dom';
import { Card, CardContent, Grid } from '@mui/material';
import { Service } from '../interface/service';
import ServiceService from '../services/service.service';
import { Link as RouterLink } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';

import Title from './Title';

interface User {
  id: string;
  username: string;
}

const ServiceDetail: React.FC = () => {
  const location = useLocation();
  const [service, setService] = useState<Service>();
  const [serviceId, setServiceId] = useState<string>('');

  const fetchService = (id: string) => {
    ServiceService.getService(id)
      .then((response) => {
        setService(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const id = location.pathname.split('/').pop();
    if (id) {
      setServiceId(id);
      fetchService(id);
    }
  }, []);

  return (
    <>
      {service ? (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <Title>{service.service}</Title>
          </Grid>
          <Grid item key={serviceId} xs={12} sm={12} md={12}>
            <Card>
              <CardContent>
                <ListItemText primary={`ID: ${service.id}`} />
             </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
          <Typography>Test</Typography>
          </Grid>


        </Grid>
      ) : (
        <Typography variant='h5' component='div'>
          Loading ..
        </Typography>
      )}
    </>
  );
};

export default ServiceDetail;
