// SettingsPage.tsx
import React, { useState } from 'react';
import {
  Container,
  Typography,
  FormControl,
  FormLabel,
  FormControlLabel,
  Switch,
  Button,
  Grid,
  Box,
  Paper,
} from '@mui/material';

const NotificationSetting: React.FC = () => {
  const [setting1, setSetting1] = useState<boolean>(false);
  const [setting2, setSetting2] = useState<boolean>(false);
  const [setting3, setSetting3] = useState<boolean>(false);
  const [setting4, setSetting4] = useState<boolean>(false);
  const [setting5, setSetting5] = useState<boolean>(false);
  const [setting6, setSetting6] = useState<boolean>(false);
  const [setting7, setSetting7] = useState<boolean>(false);
  const [setting8, setSetting8] = useState<boolean>(false);
  const [setting9, setSetting9] = useState<boolean>(false);

  return (
    <Container>
      <Grid container spacing={3}>
        {/* Grid Item 1 */}
        <Grid item xs={6} md={6}>
          <Paper elevation={3} style={{ padding: '16px' }}>
            <FormControl component='fieldset'>
              <FormLabel component='legend'>Mention</FormLabel>
              <FormControlLabel
                control={
                  <Switch
                    checked={setting1}
                    onChange={() => setSetting1((prev) => !prev)}
                  />
                }
                label='Direct'
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={setting2}
                    onChange={() => setSetting2((prev) => !prev)}
                  />
                }
                label='Group'
              />
            </FormControl>
          </Paper>
        </Grid>

        {/* Grid Item 2 */}
        <Grid item xs={6} md={6}>
          <Paper elevation={3} style={{ padding: '16px' }}>
            <FormControl component='fieldset'>
              <FormLabel component='legend'>From</FormLabel>
              <FormControlLabel
                control={
                  <Switch
                    checked={setting4}
                    onChange={() => setSetting4((prev) => !prev)}
                  />
                }
                label='Superior'
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={setting5}
                    onChange={() => setSetting5((prev) => !prev)}
                  />
                }
                label='Collaborator'
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={setting6}
                    onChange={() => setSetting6((prev) => !prev)}
                  />
                }
                label='Attention Group'
              />
            </FormControl>
          </Paper>
        </Grid>
        {/* Mission */}
        <Grid item xs={4} md={4}>
          <Paper elevation={3} style={{ padding: '16px' }}>
            <FormControl component='fieldset'>
              <FormLabel component='legend'>Mission </FormLabel>
              <FormControlLabel
                control={
                  <Switch
                    checked={setting7}
                    onChange={() => setSetting7((prev) => !prev)}
                  />
                }
                label='Invitation'
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={setting8}
                    onChange={() => setSetting8((prev) => !prev)}
                  />
                }
                label='Task'
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={setting9}
                    onChange={() => setSetting9((prev) => !prev)}
                  />
                }
                label='Complete'
              />
            </FormControl>
          </Paper>
        </Grid>

        {/* Task */}
        <Grid item xs={4} md={4}>
          <Paper elevation={3} style={{ padding: '16px' }}>
            <FormControl component='fieldset'>
              <FormLabel component='legend'>Task </FormLabel>
              <FormControlLabel
                control={
                  <Switch
                    checked={setting7}
                    onChange={() => setSetting7((prev) => !prev)}
                  />
                }
                label='Invitation'
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={setting8}
                    onChange={() => setSetting8((prev) => !prev)}
                  />
                }
                label='Document'
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={setting9}
                    onChange={() => setSetting9((prev) => !prev)}
                  />
                }
                label='Mission'
              />
            </FormControl>
          </Paper>
        </Grid>

        {/* Document */}
        <Grid item xs={4} md={4}>
          <Paper elevation={3} style={{ padding: '16px' }}>
            <FormControl component='fieldset'>
              <FormLabel component='legend'>Document</FormLabel>
              <FormControlLabel
                control={
                  <Switch
                    checked={setting7}
                    onChange={() => setSetting7((prev) => !prev)}
                  />
                }
                label='Invitation'
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={setting8}
                    onChange={() => setSetting8((prev) => !prev)}
                  />
                }
                label='Issue'
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={setting9}
                    onChange={() => setSetting9((prev) => !prev)}
                  />
                }
                label='Signature'
              />
            </FormControl>
          </Paper>
        </Grid>

        {/* Issue */}
        <Grid item xs={6} md={6}>
          <Paper elevation={3} style={{ padding: '16px' }}>
            <FormControl component='fieldset'>
              <FormLabel component='legend'>Issue</FormLabel>
              <FormControlLabel
                control={
                  <Switch
                    checked={setting7}
                    onChange={() => setSetting7((prev) => !prev)}
                  />
                }
                label='Invitation'
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={setting8}
                    onChange={() => setSetting8((prev) => !prev)}
                  />
                }
                label='Process'
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={setting9}
                    onChange={() => setSetting9((prev) => !prev)}
                  />
                }
                label='Signature'
              />
            </FormControl>
          </Paper>
        </Grid>

        {/* Process */}
        <Grid item xs={6} md={6}>
          <Paper elevation={3} style={{ padding: '16px' }}>
            <FormControl component='fieldset'>
              <FormLabel component='legend'>Process</FormLabel>
              <FormControlLabel
                control={
                  <Switch
                    checked={setting7}
                    onChange={() => setSetting7((prev) => !prev)}
                  />
                }
                label='Solution'
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={setting8}
                    onChange={() => setSetting8((prev) => !prev)}
                  />
                }
                label='Verified'
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={setting9}
                    onChange={() => setSetting9((prev) => !prev)}
                  />
                }
                label='Edit'
              />

              <FormControlLabel
                control={
                  <Switch
                    checked={setting9}
                    onChange={() => setSetting9((prev) => !prev)}
                  />
                }
                label='Issue'
              />
            </FormControl>
          </Paper>
        </Grid>

        {/* Save Button */}

        <Grid item xs={12} md={12}>
          <Button variant='contained' color='primary'>
            Save
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default NotificationSetting;
