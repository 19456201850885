import axios from 'axios';
import authHeader from './auth-header';

const axiosBackend = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL,
});

class CronService {
  fetchCronJobs() {
    return axios.get(axiosBackend.defaults.baseURL + 'cronjob/', {
      headers: authHeader(),
    });
  }

  fetchCronSchedules() {
    return axios.get(axiosBackend.defaults.baseURL + 'schedule/', {
      headers: authHeader(),
    });
  }

}

export default new CronService();
