import React from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import jwt_decode from 'jwt-decode';

interface JWTDecodeProps {
  jwtKey: string;
}

const JWTDecodeComponent: React.FC<JWTDecodeProps> = ({ jwtKey }) => {
  const [decodedToken, setDecodedToken] = React.useState('');
  const [inputToken, setInputToken] = React.useState('');

  const handleDecode = () => {
    try {
      const decoded = jwt_decode(inputToken);
      setDecodedToken(JSON.stringify(decoded, null, 2));
    } catch (error) {
      setDecodedToken('Invalid JWT token');
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputToken(e.target.value);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader title='JWT Token Decoder' />
          <CardContent>
            <TextField
              label='JWT Token'
              multiline
              rows={6}
              fullWidth
              value={inputToken}
              onChange={handleInputChange}
              margin='normal'
              variant='outlined'
            />
            <Button variant='contained' color='primary' onClick={handleDecode}>
              Decode
            </Button>
          </CardContent>
        </Card>
      </Grid>

      {decodedToken && (
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant='h6' gutterBottom>
                Decoded Token:
              </Typography>
              <pre>{decodedToken}</pre>
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};

export default JWTDecodeComponent;
