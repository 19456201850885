// src/components/ModelBList.tsx

import React, { useEffect, useState } from 'react';
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';

import { Card, CardContent, Grid } from '@mui/material';
import { Mission } from '../interface/mission';
import MissionService from '../services/mission.service';
import { Link as RouterLink } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';

import Title from './Title';

interface User {
  id: string;
  username: string;
}

const MissionList: React.FC = () => {
  const [missions, setMissions] = useState<Mission[]>([]);

  const fetchMissions = () => {
    MissionService.getMissions()
      .then((response) => {
        setMissions(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchMissions();
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item key={2} xs={12} sm={12} md={12}>
        <List>
          {missions.map((mission) => (
            <Card>
              <CardContent>
                <MuiLink
                  color='primary'
                  component={RouterLink}
                  to={`/missions/${mission.id}`}
                  underline='none'
                >
                  <Title>{mission.name}</Title>
                </MuiLink>

                <ListItemText primary={`ID: ${mission.id}`} />
                <ListItemText
                  primary={`Active: ${mission.active ? 'Yes' : 'No'}`}
                />
                <ListItemText primary={`Timestamp: ${mission.timestamp}`} />
                <ListItemText primary={`Deadline: ${mission.deadline}`} />
                <ListItemText primary={`Owner: ${mission.owner}`} />
                <ListItemText
                  primary={`Admins: ${
                    mission.admins.length > 0
                      ? mission.admins.join(', ')
                      : 'None'
                  }`}
                />
                <ListItemText
                  primary={`Users: ${
                    mission.users.length > 0 ? mission.users.join(', ') : 'None'
                  }`}
                />
              </CardContent>
            </Card>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default MissionList;
