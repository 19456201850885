import { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/system';
import axiosBackend from '../services/axios-backend';
import { useLocation } from 'react-router-dom';
import { Grid, CircularProgress, TextField, Typography } from '@mui/material';
import { Paper } from '@mui/material';
import { SendOutlined } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';

import { useNavigate, useParams } from 'react-router-dom';
import { Service } from '../interface/service';
import ServiceService from '../services/document.service';
import Title from './Title';

export type ServiceTagProps = {
  id:number;
};

//const ServiceTag: React.FC<ServiceTagProps> = () => {
export default function ServiceTag(props: ServiceTagProps) {
  const { id: serviceId } = props
  const [service, setService] = useState<Service | undefined>(undefined);

  const fetchService = () => {
    axiosBackend
      .get(`/services/${serviceId}`)
      .then((r) => {
       setService(r.data);
      })
      .catch((e) => {
        console.log(e);
        console.error(`Unable to fetch service ID ${serviceId}.`);
      });
  };

  useEffect(() => {
    if (!service) {
      fetchService();
    } 
    return () => {};
  }, [service, ]);

  return (

    <>
      {service ? (
        <Typography>
          Service: {service.service}
          </Typography>
      ) : (
      <CircularProgress></CircularProgress>
      )}
    </>
  );
};

