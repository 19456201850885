import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Settings from './Settings';
import CronJobList from './CronJobList';
import CronScheduleList from './CronScheduleList';

interface CrontabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CronTabPanel(props: CrontabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='crontab-panel'
      hidden={value !== index}
      id={`crontab-tabpanel-${index}`}
      aria-labelledby={`crontab-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box mr={2} sx={{ p: 3 ,
          overflow: 'hidden',
        }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    'id': `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function CronTab() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='crontab'
          >
            <Tab label='Schedule' {...a11yProps(0)} />
            <Tab label='Jobs' {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CronTabPanel value={value} index={0}>
        <CronScheduleList></CronScheduleList>
        </CronTabPanel>
        <CronTabPanel value={value} index={1}>
          <CronJobList/>
        </CronTabPanel>
      </Box>
    </Paper>
  );
}
