import React, { useEffect, useState } from 'react';
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';

import { useLocation } from 'react-router-dom';
import { Card, CardContent, Grid } from '@mui/material';
import { Stamp } from '../interface/stamp';
import StampService from '../services/stamp.service';
import { Link as RouterLink } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';

import Title from './Title';

interface User {
  id: string;
  username: string;
}

const StampDetail: React.FC = () => {
  const location = useLocation();
  const [stamp, setStamp] = useState<Stamp>();
  const [stampId, setStampId] = useState<string>('');

  const fetchStamp = (id: string) => {
    StampService.getStamp(id)
      .then((response) => {
        setStamp(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const id = location.pathname.split('/').pop();
    if (id) {
      setStampId(id);
      fetchStamp(id);
    }
  }, []);

  return (
    <>
      {stamp ? (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <Title>{stamp.name}</Title>
          </Grid>
          <Grid item key={stampId} xs={12} sm={12} md={12}>
            <Card>
              <CardContent>
                <ListItemText primary={`ID: ${stamp.id}`} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Typography>{stamp.timestamp}</Typography>
          </Grid>
        </Grid>
      ) : (
        <Typography variant='h5' component='div'>
          Loading ..
        </Typography>
      )}
    </>
  );
};

export default StampDetail;
