import OrderList from './ProcessIssues';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

const Processes = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <OrderList />
      </Grid>
    </Grid>
  );
};

export default Processes;
