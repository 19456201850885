import axios, { AxiosResponse } from 'axios';

import authHeader from './auth-header';
import { IssueInputType } from '../interface/issue';
import { DocumentIssueInputType } from '../interface/document';

const axiosBackend = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL,
});

class IssueService {
  getIssues() {
    return axios.get(axiosBackend.defaults.baseURL + 'issues/', {
      headers: authHeader(),
    });
  }

  getIssue(id: string) {
    return axios.get(axiosBackend.defaults.baseURL + 'issues/' + id, {
      headers: authHeader(),
    });
  }
  getProcesses(id: string) {
    return axios.get(
      axiosBackend.defaults.baseURL + 'processes/?issue_id=' + id,
      {
        headers: authHeader(),
      }
    );
  }

  getIssueSolutions(id: string) {
    return axios.get(
      axiosBackend.defaults.baseURL + 'processes/?solution=True&issue_id=' + id,
      {
        headers: authHeader(),
      }
    );
  }

  async postIssue(issue: IssueInputType): Promise<any> {
    try {
      const response: AxiosResponse = await axios.post(
        axiosBackend.defaults.baseURL + 'issues/',
        issue,
        {
          headers: authHeader(),
        }
      );

      console.log('API response:', response.data);
      return response.data;
    } catch (error) {
      console.error('API error:', error);
      throw error;
    }
 
  }

  async postDocumentIssue(issue: DocumentIssueInputType): Promise<any> {
    try {
      const response: AxiosResponse = await axios.post(
        axiosBackend.defaults.baseURL + 'issues/',
        issue,
        {
          headers: authHeader(),
        }
      );

      console.log('API response:', response.data);
      return response.data;
    } catch (error) {
      console.error('API error:', error);
      throw error;
    }
  }
}

export default new IssueService();
