
import axios, { AxiosResponse } from 'axios';

import authHeader from './auth-header';
import { Signature } from '../interface/signature';

const axiosBackend = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL,
});

class SignatureService {
  getSignatures() {
    return axios.get(axiosBackend.defaults.baseURL + 'signatures/', {
      headers: authHeader(),
    });
  }

  getSignature(id: string) {
    return axios.get(axiosBackend.defaults.baseURL + 'signatures/' + id, {
      headers: authHeader(),
    });
  }

}

export default new SignatureService();
