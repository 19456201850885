import React, { useEffect, useState } from 'react';
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';
import { AssetType } from '../interface/asset';
import AssetService from '../services/asset.service';
import { Link as RouterLink } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';
import Title from './Title';

interface User {
  id: string;
  username: string;
}

const AssetList: React.FC = () => {
  const [assets, setAssets] = useState<AssetType[]>([]);

  const fetchAssets = () => {
    AssetService.getAssets()
      .then((response) => {
        setAssets(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchAssets();
  }, []);

  return (
    <Box>
      <List>
        {assets.map((s) => (
          <Box
            key={s.id}
            p={2}
            border='1px solid #ccc'
            borderRadius='4px'
            my={2}
            sx={{ backgroundColor: 'white' }}
          >
            <MuiLink
              color='primary'
              component={RouterLink}
              to={`/processes/${s.process}`}
              underline='none'
            >
              <Title>{s.process}</Title>
            </MuiLink>

            <MuiLink
              color='primary'
              component={RouterLink}
              to={`/assets/${s.id}`}
              underline='none'
            >
              <ListItemText primary={`ID: ${s.id}`} />
            </MuiLink>
            <ListItemText primary={`Timestamp: ${s.timestamp}`} />
            <ListItemText primary={`Master: ${s.master}`} />
          </Box>
        ))}
      </List>
    </Box>
  );
};

export default AssetList;
