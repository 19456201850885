import axios, { AxiosResponse } from 'axios';

import authHeader from './auth-header';
import { Mission } from '../interface/mission';

const axiosBackend = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL,
});

class MissionService {
  getMissions() {
    return axios.get(axiosBackend.defaults.baseURL + 'missions/', {
      headers: authHeader(),
    });
  }

  getMission(id: string) {
    return axios.get(axiosBackend.defaults.baseURL + 'missions/' + id, {
      headers: authHeader(),
    });
  }
  getMissionTasks(id: string) {
    return axios.get(
      axiosBackend.defaults.baseURL + 'tasks/?mission=' + id,
      {
        headers: authHeader(),
      }
    );
  }

  async postMission(mission: Mission): Promise<any> {
    try {
      const response: AxiosResponse = await axios.post(
        axiosBackend.defaults.baseURL + 'mission/',
        mission,
        {
          headers: authHeader(),
        }
      );

      console.log('API response:', response.data);
      return response.data;
    } catch (error) {
      console.error('API error:', error);
      throw error;
    }
  }
}

export default new MissionService();
