import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Card, CardContent, Typography } from '@mui/material';
import AuthService from '../services/auth.service';
import IUser from '../types/user.type';
import UserService from '../services/user.service';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TokenContainer from './TokenContainer';
import Account from './Account';

type Props = {};

const Profile: React.FC<Props> = () => {
  const [redirect, setRedirect] = useState<string | null>(null);
  const [userReady, setUserReady] = useState(false);
  const [currentUser, setCurrentUser] = useState<
    IUser & { access: string; refresh: string }
  >({
    access: '',
    refresh: '',
  });
  const [content, setContent] = useState<any>({});

  useEffect(() => {
    const currentUser = AuthService.getCurrentUser();

    if (!currentUser) {
      setRedirect('/');
    } else {
      UserService.getPublicContent()
        .then((response) => {
          setContent(response.data);
        })
        .catch((error) => {
          setContent(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );
        });

      setCurrentUser(currentUser);
      setUserReady(true);
    }
  }, []);

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8} lg={9}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 240,
          }}
        >
          <TokenContainer title='Acess Token' text={currentUser.access} />
        </Paper>
      </Grid>
      {userReady ? (
        <Grid item xs={12} md={4} lg={3}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 240,
            }}
          >
            <Account
              title='Account'
              id={content.id}
              email={content.email}
              username={content.username}
              first_name={content.first_name}
              last_name={content.last_name}
            />
          </Paper>
        </Grid>
      ) : null}
      <Grid item xs={12} md={8} lg={9}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 240,
          }}
        >
          <TokenContainer title='Refresh Token' text={currentUser.refresh} />
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}></Paper>
      </Grid>
    </Grid>
  );
};

export default Profile;
