import * as React from 'react';
import { useEffect } from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import IssueService from '../services/issue.service';
import Title from './Title';

import { Link as RouterLink } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';

import { IssuesType } from '../interface/issue';
import Solutions from './Solutions';

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

export default function IssueList() {
  const [issues, setIssues] = React.useState<IssuesType>([]);

  const fetchIssues = () => {
    IssueService.getIssues()
      .then((response) => {
        setIssues(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchIssues();
  }, []);

  return (
    <Grid container spacing={3}>
      {issues.map((issue) => (
        <Grid item key={issue.id} xs={12} sm={12} md={12}>
          <Card>
            <CardContent>
              <MuiLink
                color='primary'
                component={RouterLink}
                to={`/issues/${issue.id}`}
                underline='none'
              >
                <Title>{issue.name}</Title>
              </MuiLink>
              <Typography color='textSecondary' gutterBottom>
                URL: {issue.url}
              </Typography>
              <Typography color='textSecondary' gutterBottom>
                Active: {issue.active ? 'Yes' : 'No'}
              </Typography>
              <Typography color='textSecondary' gutterBottom>
                Creator: {issue.creator}
              </Typography>
              <Typography color='textSecondary' gutterBottom>
                Timestamp: {issue.timestamp}
              </Typography>
              <Typography color='textSecondary' gutterBottom>
                Deadline: {issue.deadline}
              </Typography>


              <Typography color='textSecondary' gutterBottom>
                Admins: {issue.admins.join(', ')}
              </Typography>
              <Typography color='textSecondary' gutterBottom>
                Users: {issue.users.join(', ')}
              </Typography>

              {issue.document && (
                <Typography color='textSecondary' gutterBottom>
                  Document: {issue.document}
                </Typography>
              )}

              {issue.process && (
                <Typography color='textSecondary' gutterBottom>
                  Process: {issue.process}
                </Typography>
              )}


              <Solutions issue={issue}></Solutions>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
