import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, CardMedia } from '@mui/material';
import AssetService from '../services/asset.service';
import { Box, List, ListItem, ListItemText } from '@mui/material';

import { useLocation } from 'react-router-dom';
import { AssetType } from '../interface/asset';
import { Link as RouterLink } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';
const AssetDetail: React.FC = () => {
  const [asset, setAsset] = useState<AssetType>();
  const [fileContent, setFileContent] = useState<string | null>(null);
  const routeLocation = useLocation();

  const fetchAsset = (id: string) => {
    AssetService.getAsset(id)
      .then((response) => {
        setAsset(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const id = routeLocation.pathname.split('/').pop();
    if (id) {
      fetchAsset(id);
    }
  }, []);

  useEffect(() => {
    if (!asset) return;

    const fileExtension = asset.file.split('.').pop()?.toLowerCase();

    if (fileExtension === 'txt' || fileExtension === 'json') {
      fetch(asset.file)
        .then((response) => response.text())
        .then((data) => {
          setFileContent(data);
        })
        .catch((error) => {
          console.error('There was an error fetching the text file:', error);
        });
    }
  }, [asset]);

  const renderContentBasedOnFileType = () => {
    if (!asset) return null;

    const fileExtension = asset.file.split('.').pop()?.toLowerCase();

    switch (fileExtension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return <CardMedia component='img' image={asset.file} alt='Asset' />;

      case 'txt':
        return (
          <Typography variant='body1' component='pre'>
            {fileContent}
          </Typography>
        );

      case 'json':
        return (
          <Typography
            variant='body1'
            component='pre'
            style={{
              maxHeight: '200px', // adjust as necessary
              overflowY: 'auto',
              wordWrap: 'break-word',
              whiteSpace: 'pre-wrap',
            }}
          >
            {fileContent}
          </Typography>
        );

      case 'mp4':
      case 'webm':
        return <CardMedia component='video' controls src={asset.file} />;

      // ... handle other file types as needed

      default:
        return <Typography variant='body1'>Unsupported file type.</Typography>;
    }
  };

  return (
    <Card>
      <CardContent>
        {asset ? (
          renderContentBasedOnFileType()
        ) : (
          <Typography>Loading...</Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default AssetDetail;
