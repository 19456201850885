import Routing from './Routing';
import Sidebar from './components/Sidebar';
import Box from '@mui/material/Box';
import Footer from './components/Footer';
export default function App() {
  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <Sidebar />
      <Routing></Routing>
    </Box>
  );
}
