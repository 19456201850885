import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import MergeIcon from '@mui/icons-material/Merge';
import SolutionTimeline from './SolutionTimeline';
import { IProcess, ProcessType } from '../interface/process';
import ProcessAssets from './ProcessAssets';
import processService from '../services/process.service';

interface ProcessProps {
  process: ProcessType;
  onUpdate: (newData: string) => void;
}
interface ExpandMoreProps extends IconButtonProps {
  done: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { done, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, done }) => ({
  transform: !done ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function Solution(props: ProcessProps) {
  const { process, onUpdate } = props;
  const [done, setDone] = React.useState(false);

  const handleDoneClick = () => {
    setDone(!done);
  };

  const handleSolutionClick = () => {
    processService.markProcessAsSolution(process.id, process.text, false);
    onUpdate("Just Rejected a Solution")
  };

  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label='recipe'>
            {process.user.charAt(0).toUpperCase()}
          </Avatar>
        }
        action={
          <IconButton aria-label='Not A Solution' onClick={handleSolutionClick}>
            <DeleteIcon />
          </IconButton>
        }
        title={process.id}
        subheader={process.timestamp.toLocaleString()}
      />
      <CardContent>
        <Typography variant='body2' color='text.secondary'>
          {process.text}
        </Typography>
        <ProcessAssets process={process}></ProcessAssets>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label='share'>
          <MergeIcon />
        </IconButton>
        <ExpandMore
          done={done}
          onClick={handleDoneClick}
          aria-expanded={done}
          aria-label='show more'
        >
          <ThumbDownIcon />
        </ExpandMore>
      </CardActions>
    </Card>
  );
}
