import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Settings from './Settings';
import NotificationPreferences from './NotificationPreferences';
import AddressForm from './AddressForm';
import PaymentForm from './PaymentForm';
import NotificationSetting from './NotificationSetting';
import NotificationLoop from './NotificationLoop';
import NotificationList from './NotificationList';

interface PreferencesPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function PreferencesPanel(props: PreferencesPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='preferences-panel'
      hidden={value !== index}
      id={`preferences-tabpanel-${index}`}
      aria-labelledby={`preferences-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    'id': `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Preferences() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab label='List' {...a11yProps(0)} />
            <Tab label='Policy' {...a11yProps(1)} />
            <Tab label='Runtime' {...a11yProps(2)} />
            <Tab label='Rules' {...a11yProps(2)} />
          </Tabs>
        </Box>
        <PreferencesPanel value={value} index={0}>

        <NotificationList></NotificationList>
        </PreferencesPanel>
        <PreferencesPanel value={value} index={1}>
          <NotificationSetting></NotificationSetting>
        </PreferencesPanel>

        <PreferencesPanel value={value} index={2}>
          <NotificationLoop></NotificationLoop>
        </PreferencesPanel>

        <PreferencesPanel value={value} index={3}>
          <NotificationPreferences></NotificationPreferences>
        </PreferencesPanel>
 
      </Box>
    </Paper>
  );
}
