// src/components/ModelBList.tsx

import React, { useEffect, useState } from 'react';
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';
import { Task } from '../interface/task';
import TaskService from '../services/task.service';
import { Link as RouterLink } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';
import Title from './Title';

interface User {
  id: string;
  username: string;
}

const TaskList: React.FC = () => {
  const [tasks, setTasks] = useState<Task[]>([]);

  const fetchTasks = () => {
    TaskService.getTasks()
      .then((response) => {
        setTasks(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchTasks();
  }, []);

  return (
    <Box>
      <List>
        {tasks.map((task) => (
          <Box
            key={task.id}
            p={2}
            border='1px solid #ccc'
            borderRadius='4px'
            my={2}
            sx={{ backgroundColor: 'white' }}
          >
            <MuiLink
              color='primary'
              component={RouterLink}
              to={`/tasks/${task.id}`}
              underline='none'
            >
              <Title>{task.name}</Title>
            </MuiLink>

            <ListItemText primary={`ID: ${task.id}`} />
            <ListItemText primary={`Active: ${task.active ? 'Yes' : 'No'}`} />
            <ListItemText primary={`Timestamp: ${task.timestamp}`} />
            <ListItemText primary={`Deadline: ${task.deadline}`} />
            <ListItemText primary={`Cost: ${task.cost}`} />
            <ListItemText primary={`Leader: ${task.leader || 'None'}`} />
            <ListItemText
              primary={`Mission: ${
                task.mission.length > 0 ? task.mission.join(', ') : 'None'
              }`}
            />
            <ListItemText
              primary={`Admins: ${
                task.admins.length > 0 ? task.admins.join(', ') : 'None'
              }`}
            />
            <ListItemText
              primary={`Users: ${
                task.users.length > 0 ? task.users.join(', ') : 'None'
              }`}
            />
            {/* 
            <ListItemText
              primary={`Approvers: ${
                task.approvers.length > 0 ? task.approvers.join(', ') : 'None'
              }`}
            />
*/}
          </Box>
        ))}
      </List>
    </Box>
  );
};

export default TaskList;
