import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import DocumentIssueInput from './DocumentIssueInput';
import DocumentService from '../services/document.service';
import {DocumentType} from '../interface/document'

const DocumentIssueAdd = () => {
  const location = useLocation();
  const [documentData, setDocumentData] = useState<DocumentType>();

  const extractUUIDFromURL = (): string | null => {
    const segments = location.pathname.split('/');
    const uuidIndex = segments.length - 2;

    if (uuidIndex >= 0) {
      const uuid = segments[uuidIndex];
      return uuid;
    }

    return null;
  };

  const fetchDocument = (id: string) => {
    DocumentService.getDocument(id)
      .then((response) => {
        console.log(response.data);
        setDocumentData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const id = extractUUIDFromURL();
    if (id) {
      fetchDocument(id);
    }
  }, [location]);
  return (
    <Grid container spacing={3}>
      {documentData ? (
        <Grid item xs={12}>
        <DocumentIssueInput document={documentData}></DocumentIssueInput>
        </Grid>
      ) : (
        <Paper>No Proc</Paper>
      )}
    </Grid>
  );
};

export default DocumentIssueAdd;
