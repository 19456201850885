import React from 'react';
import { useLocation, useNavigate } from 'react-router';

import Dashboard from './Dashboard';
import Footer from './Footer';
const Sidebar = () => {
  const useAuth = () => {
    const user = localStorage.getItem('user');
    if (user) {
      return true;
    } else {
      return false;
    }
  };
  const user = useAuth();
  const location = useLocation();
  const navigation = useNavigate();

  return <>{user && <Dashboard></Dashboard>}</>;
};

export default Sidebar;
