import React, { useState, useEffect } from 'react';
import Markdown from 'marked-react';

interface MarkdownDisplayProps {
  fileUrl: string;
}

const MarkdownDisplay: React.FC<MarkdownDisplayProps> = ({ fileUrl }) => {
  const [markdownContent, setMarkdownContent] = useState<string>('');

  useEffect(() => {
    const fetchMarkdownFile = async () => {
      try {
        const response = await fetch(fileUrl);
        const content = await response.text();
        console.log(response)
        setMarkdownContent(content);
      } catch (error) {
        console.error('Error fetching markdown file:', error);
      }
    };

    fetchMarkdownFile();
  }, [fileUrl]);

  return (
    <div>
      <Markdown>{markdownContent}</Markdown>
    </div>
  );
};

export default MarkdownDisplay;
