import { Search } from '@mui/icons-material';
import * as React from 'react';

import IssueList from './IssueList';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
const Issues = () => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <IssueList />
        </Grid>
      </Grid>
    </>
  );
};

export default Issues;
