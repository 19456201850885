import Grid from '@mui/material/Grid';
import NotificationsList from './NotificationList';

const Notifications = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
      <NotificationsList></NotificationsList>
      </Grid>
    </Grid>
  );
};

export default Notifications;
