import axios from 'axios';

const axiosBackend = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL,
});

class AuthService {
  login(username: any, password: any) {
    return axios
      .post(axiosBackend.defaults.baseURL + 'token/', {
        username,
        password,
      })
      .then((response) => {
        console.log(response);
        if (response.data.access) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data.access;
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  getCurrentUser() {
    const userStr = localStorage.getItem('user');
    if (userStr) return JSON.parse(userStr);

    return null;
  }
}

export default new AuthService();
