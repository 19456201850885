import axios from 'axios';
import authHeader from './auth-header';

const axiosBackend = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL,
});

class UserService {
  getPublicContent() {
    return axios.get(axiosBackend.defaults.baseURL + 'accounts/user', {
      headers: authHeader(),
    });
  }

  getUserBoard() {
    return axios.get(axiosBackend.defaults.baseURL + 'accounts/user', {
      headers: authHeader(),
    });
  }
}

export default new UserService();
