import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BarChartIcon from '@mui/icons-material/BarChart';
import HomeIcon from '@mui/icons-material/Home';
import ProfileIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import KeyIcon from '@mui/icons-material/Key';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import DescriptionIcon from '@mui/icons-material/Description';
import CodeIcon from '@mui/icons-material/Code';
import NotificationsIcon from '@mui/icons-material/Notifications';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import MonitorIcon from '@mui/icons-material/Monitor';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import StorageIcon from '@mui/icons-material/Storage';
import MarkChatUnreadIcon from '@mui/icons-material/MarkChatUnread';
import VerifiedIcon from '@mui/icons-material/Verified';
import ApprovalIcon from '@mui/icons-material/Approval';
import WebAssetIcon from '@mui/icons-material/WebAsset';

export const mainListItems = (
  <React.Fragment>
    <ListItem button component={RouterLink} to='/'>
      <ListItemIcon>
        <HomeIcon />
      </ListItemIcon>
      <ListItemText primary='Home' />
    </ListItem>
    <ListItem button component={RouterLink} to='/profile'>
      <ListItemIcon>
        <ProfileIcon />
      </ListItemIcon>
      <ListItemText primary='Profile' />
    </ListItem>
    <ListItem button component={RouterLink} to='/preferences'>
      <ListItemIcon>
        <NotificationsIcon></NotificationsIcon>
      </ListItemIcon>
      <ListItemText primary='Notification' />
    </ListItem>

    <ListItem button component={RouterLink} to='/processes/new'>
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText primary='New Process' />
    </ListItem>
    <ListItem button component={RouterLink} to='/issues'>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary='Issues' />
    </ListItem>

    <ListItem button component={RouterLink} to='/mentions'>
      <ListItemIcon>
        <MarkChatUnreadIcon></MarkChatUnreadIcon>
      </ListItemIcon>
      <ListItemText primary='Mentions' />
    </ListItem>

    <ListItem button component={RouterLink} to='/signatures'>
      <ListItemIcon>
        <VerifiedIcon></VerifiedIcon>
      </ListItemIcon>
      <ListItemText primary='Signatures' />
    </ListItem>

    <ListItem button component={RouterLink} to='/stamps'>
      <ListItemIcon>
        <ApprovalIcon></ApprovalIcon>
      </ListItemIcon>
      <ListItemText primary='Stamps' />
    </ListItem>

    <ListItem button component={RouterLink} to='/assets'>
      <ListItemIcon>
      <WebAssetIcon></WebAssetIcon>
      </ListItemIcon>
      <ListItemText primary='Assets' />
    </ListItem>


    <ListItem button component={RouterLink} to='/documents'>
      <ListItemIcon>
        <DescriptionIcon></DescriptionIcon>
      </ListItemIcon>
      <ListItemText primary='Documents' />
    </ListItem>
    <ListItem button component={RouterLink} to='/tasks'>
      <ListItemIcon>
        <CodeIcon></CodeIcon>
      </ListItemIcon>
      <ListItemText primary='Tasks' />
    </ListItem>

    <ListItem button component={RouterLink} to='/missions'>
      <ListItemIcon>
        <RocketLaunchIcon></RocketLaunchIcon>
      </ListItemIcon>
      <ListItemText primary='Missions' />
    </ListItem>

  </React.Fragment>
);

export const secondaryListItems = (
  <React.Fragment>
    <ListItem button component={RouterLink} to='/jwt'>
      <ListItemIcon>
        <KeyIcon />
      </ListItemIcon>
      <ListItemText primary='JWT Decoder' />
    </ListItem>
    <ListItem button component={RouterLink} to='/audit'>
      <ListItemIcon>
        <MonitorIcon></MonitorIcon>
      </ListItemIcon>
      <ListItemText primary='Audit' />
    </ListItem>

    <ListItem button component={RouterLink} to='/crontab'>
      <ListItemIcon>
        <AlarmOnIcon />
      </ListItemIcon>
      <ListItemText primary='Crontab' />
    </ListItem>

    <ListItem button component={RouterLink} to='/services'>
      <ListItemIcon>
        <StorageIcon></StorageIcon>
      </ListItemIcon>
      <ListItemText primary='Services' />
    </ListItem>

    <ListItem button component={RouterLink} to='/reports'>
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary='Reports' />
    </ListItem>
  </React.Fragment>
);
