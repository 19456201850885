import axios from 'axios';
import authHeader from './auth-header';
import authMultipartHeader from './auth-header';

const axiosBackend = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL,
});

class DocumentService {
  getDocuments() {
    return axios.get(axiosBackend.defaults.baseURL + 'documents/', {
      headers: authHeader(),
    });
  }

  getTaskDocuments(id:string) {
    return axios.get(axiosBackend.defaults.baseURL + 'documents/?task=' + id, {
      headers: authHeader(),
    });
  }


  getDocument(document: string) {
    return axios.get(axiosBackend.defaults.baseURL + 'documents/' + document, {
      headers: authHeader(),
    });
  }

  updateDocument(document: string, data: string ) {
    const formData = new FormData();
    const file = new File([data], document + '.md', {
      type: 'application/json',
    });
    formData.append('file', file);
    let authentication = authMultipartHeader();
    return axios
      .put(axiosBackend.defaults.baseURL + `documents/${document}/`, formData, {
        headers: authentication,
      })
      .then((response) => {
        console.log('API response:', response.data);
      })
      .catch((error) => {
        console.error('API error:', error);
      });
  }
}

export default new DocumentService();
