import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ForkRightIcon from '@mui/icons-material/ForkRight';
import VerifiedIcon from '@mui/icons-material/Verified';
import NoteIcon from '@mui/icons-material/Note';
import { IProcess, ProcessType } from '../interface/process';
import { Link as RouterLink } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Delete } from '@mui/icons-material';
import ApprovalIcon from '@mui/icons-material/Approval';
import ArchiveIcon from '@mui/icons-material/Archive';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import FileList from './FileList';
import processService from '../services/process.service';
import { ProcessProps } from '../interface/process';
import ProcessAssetList from './ProcessAssetList';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const files: File[] = [
  new File(['file1 contents'], 'file1.txt'),
  new File(['file2 contents'], 'file2.jpg'),
];

export default function Process(props: ProcessProps) {
  const { process } = props;
  const [expanded, setExpanded] = React.useState(false);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [selectedButtons, setSelectedButtons] = React.useState<string[]>([]);

  const handleButtonToggle = (
    event: React.MouseEvent<HTMLElement>,
    newSelectedButtons: string[]
  ) => {
    setSelectedButtons(newSelectedButtons);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleDeleteClick = () => {
    setOpenConfirmation(true);
    console.log('Will delete:', process);
  };

  const handleSignClick = () => {
    console.log('Will sign:', process);
    processService.signProcess(process);
  };

  const handleVerifyClick = () => {
    console.log('Will sign:', process);
  };

  const handleOpenIssues = () => {
    console.log('Will Take User to Issues');
  };
  const handleSolutionClick = () => {
    processService.markProcessAsSolution(process.id, process.text, true);
  };

  const handleCancelDelete = () => {
    setOpenConfirmation(false);
  };
  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label='process'>
            {process.user.charAt(0).toUpperCase()}
          </Avatar>
        }
        action={
          <IconButton aria-label='settings' onClick={handleOpenIssues}>
            <MoreVertIcon />
          </IconButton>
        }
        title={<Typography>{process.user}</Typography>}
        /* subheader={process.timestamp.toLocaleTimeString()}*/
        subheader={
          <MuiLink
            color='primary'
            component={RouterLink}
            to={`/processes/${process.id}`}
            underline='none'
          >
            <Typography variant='body2'>{process.id}</Typography>
          </MuiLink>
        }
      />
      <CardContent>
        <Typography variant='body2' color='text.secondary'>
          {process.text}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label='add to favorites' onClick={handleDeleteClick}>
          <DeleteIcon />
        </IconButton>
        <IconButton aria-label='share'>
          <ShareIcon />
        </IconButton>
        <IconButton aria-label='share'>
          <NoteIcon></NoteIcon>
        </IconButton>
        <IconButton aria-label='sign' onClick={handleVerifyClick}>
          <ApprovalIcon></ApprovalIcon>
        </IconButton>

        <IconButton aria-label='share'>
          <ArchiveIcon></ArchiveIcon>
        </IconButton>

        <IconButton aria-label='sign' onClick={handleSignClick}>
          <VerifiedIcon></VerifiedIcon>
        </IconButton>

        <Button
          component={RouterLink}
          to={`/issues/${process.id}/add`}
          aria-label='fork'
        >
          <IconButton aria-label='share'>
            <AccountTreeIcon></AccountTreeIcon>
          </IconButton>
        </Button>

        <IconButton aria-label='Mark As Solution' onClick={handleSolutionClick}>
          <KeyboardReturnIcon></KeyboardReturnIcon>
        </IconButton>

        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label='show more'
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>

      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <CardContent>
          <CardMedia>
            <ProcessAssetList process={process}></ProcessAssetList>
          </CardMedia>
        </CardContent>
      </Collapse>

      <Dialog open={openConfirmation} onClose={handleCancelDelete}>
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          Are you sure you want to delete the process?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancel</Button>
          <Button onClick={handleOpenIssues} variant='contained' color='error'>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}
