import * as React from 'react';
import { useEffect } from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { NotificationType, NotificationsType} from '../interface/notification';
import NotificationService from '../services/notification.service';
import { Typography } from '@mui/material';

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

export default function NotificationsWidget() {
  const [notifications, setNotifications] =
    React.useState<NotificationsType>([]);

  const fetchNotificationRules = () => {
    NotificationService.fetchNotifications()
      .then((response) => {
        setNotifications(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchNotificationRules();
  }, []);

  return (
    <React.Fragment>
      <Title>Notifications</Title>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>Actor</TableCell>
            <TableCell>Action</TableCell>
            <TableCell>Target</TableCell>
            <TableCell align='right'>Verb</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {notifications.map((notification) => (
            <TableRow key={notification.id}>
              <TableCell>
                <Typography>
                {notification.actor_object_id}
                </Typography>
                </TableCell>
              <TableCell>
                <Typography>
                {notification.action_object_object_id}
                </Typography>
                </TableCell>
               <TableCell>
                <Typography>
                {notification.target_object_id}
                </Typography>
                </TableCell>
               <TableCell>
                <Typography>
                {notification.verb}
                </Typography>
                </TableCell>
 
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
