import React, { useEffect, useState } from 'react';
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';

import { useLocation } from 'react-router-dom';
import { Card, CardContent, Grid } from '@mui/material';
import { Mission } from '../interface/mission';
import MissionService from '../services/mission.service';
import { Link as RouterLink } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';

import Title from './Title';
import MissionTaskList from './MissionTaskList';

interface User {
  id: string;
  username: string;
}

const MissionDetail: React.FC = () => {
  const location = useLocation();
  const [mission, setMission] = useState<Mission>();
  const [missionId, setMissionId] = useState<string>('');

  const fetchMission = (id: string) => {
    MissionService.getMission(id)
      .then((response) => {
        setMission(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const id = location.pathname.split('/').pop();
    if (id) {
      setMissionId(id);
      fetchMission(id);
    }
  }, []);

  return (
    <Grid container spacing={3}>
      {mission ? (
        <Grid item key={missionId} xs={12} sm={12} md={12}>
          <Title>{mission.name}</Title>
          <Card>
            <CardContent>
              <ListItemText primary={`ID: ${mission.id}`} />
              <ListItemText
                primary={`Active: ${mission.active ? 'Yes' : 'No'}`}
              />
              <ListItemText primary={`Timestamp: ${mission.timestamp}`} />
              <ListItemText primary={`Deadline: ${mission.deadline}`} />
              <ListItemText primary={`Owner: ${mission.owner}`} />
              <ListItemText
                primary={`Admins: ${
                  mission.admins.length > 0 ? mission.admins.join(', ') : 'None'
                }`}
              />
              <ListItemText
                primary={`Users: ${
                  mission.users.length > 0 ? mission.users.join(', ') : 'None'
                }`}

              />
              <MissionTaskList mission={mission}></MissionTaskList>
            </CardContent>

          </Card>
        </Grid>
      ) : (
        <Typography variant='h5' component='div'>
          Loading ..
        </Typography>
      )}
    </Grid>
  );
};

export default MissionDetail;
