import React, { useEffect, useState } from 'react';
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';

import { useLocation } from 'react-router-dom';
import { Card, CardContent, Grid } from '@mui/material';
import { Task } from '../interface/task';
import TaskService from '../services/task.service';
import { Link as RouterLink } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';

import Title from './Title';
import TaskDocuments from './TaskDocuments';

interface User {
  id: string;
  username: string;
}

const TaskDetail: React.FC = () => {
  const location = useLocation();
  const [task, setTask] = useState<Task>();
  const [taskId, setTaskId] = useState<string>('');

  const fetchTask = (id: string) => {
    TaskService.getTask(id)
      .then((response) => {
        setTask(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const id = location.pathname.split('/').pop();
    if (id) {
      setTaskId(id);
      fetchTask(id);
    }
  }, []);

  return (
    <>
      {task ? (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <Title>{task.name}</Title>
          </Grid>
          <Grid item key={taskId} xs={12} sm={12} md={12}>
            <Card>
              <CardContent>
                <ListItemText primary={`ID: ${task.id}`} />
                <ListItemText
                  primary={`Active: ${task.active ? 'Yes' : 'No'}`}
                />
                <ListItemText primary={`Timestamp: ${task.timestamp}`} />
                <ListItemText primary={`Deadline: ${task.deadline}`} />
                <ListItemText primary={`Owner: ${task.leader}`} />

                <ListItemText
                  primary={`Admins: ${
                    task.admins.length > 0 ? task.admins.join(', ') : 'None'
                  }`}
                />
                <ListItemText
                  primary={`Users: ${
                    task.users.length > 0 ? task.users.join(', ') : 'None'
                  }`}
                />

                <Typography>Missions:</Typography>
                {task.mission.length > 0 ? (
                  <List>
                    {task.mission.map((mission, index) => (
                      <ListItem>
                        <MuiLink
                          key={index}
                          component={RouterLink}
                          to={`/missions/${mission}`}
                        >
                          {mission}
                        </MuiLink>
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <p>None</p>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TaskDocuments task={task}></TaskDocuments>
          </Grid>


        </Grid>
      ) : (
        <Typography variant='h5' component='div'>
          Loading ..
        </Typography>
      )}
    </>
  );
};

export default TaskDetail;
