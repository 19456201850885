
import axios, { AxiosResponse } from 'axios';

import authHeader from './auth-header';
import { Service } from '../interface/service';

const axiosBackend = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL,
});

class ServiceService {
  getServices() {
    return axios.get(axiosBackend.defaults.baseURL + 'services/', {
      headers: authHeader(),
    });
  }

  getService(id: string) {
    return axios.get(axiosBackend.defaults.baseURL + 'services/' + id, {
      headers: authHeader(),
    });
  }

}

export default new ServiceService();
