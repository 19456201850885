import * as React from 'react';
import { useEffect } from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { NotificationRule, NotificationRules } from '../interface/notification';
import NotificationService from '../services/notification.service';
import { Typography } from '@mui/material';

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

export default function NotificationPreferences() {
  const [notificationRules, setNotificationRules] =
    React.useState<NotificationRules>([]);

  const fetchNotificationRules = () => {
    NotificationService.fetchNotificationRules()
      .then((response) => {
        setNotificationRules(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchNotificationRules();
  }, []);

  return (
    <React.Fragment>
      <Title>Notification Preferences</Title>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>Actor</TableCell>
            <TableCell>Action</TableCell>
            <TableCell>Target</TableCell>
            <TableCell align='right'>Subscibed</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {notificationRules.map((rule) => (
            <TableRow key={rule.id}>
              <TableCell>
                <Typography>
                {rule.actor_object_id}
                </Typography>
                </TableCell>
              <TableCell>{rule.action_object_id}</TableCell>
              <TableCell>{rule.target_object_id}</TableCell>
              <TableCell>{rule.receive_notification}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
