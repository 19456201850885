import * as React from 'react';
import { useEffect } from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import TableContainer from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { CronJob, CronSchedule} from '../interface/crontab';
import { CronJobs, CronSchedules} from '../interface/crontab';
import CronService from '../services/cron.service';
import { Typography } from '@mui/material';

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

export default function CronJobList() {
  const [jobs, setJobs] =
    React.useState<CronJobs>([]);

  const fetchCronJobs = () => {
    CronService.fetchCronJobs()
      .then((response) => {
        setJobs(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchCronJobs();
  }, []);

  return (
    <React.Fragment>
      <TableContainer component={Paper}>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>schedule</TableCell>
            <TableCell>url</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {jobs.map((job) => (
            <TableRow key={job.id}>
              <TableCell>
                <Typography>
                {job.schedule.name}
                </Typography>
                </TableCell>
              <TableCell>{job.url}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
    </React.Fragment>
  );
}
