import React from 'react';
import { useTheme } from '@mui/material/styles';
import Title from './Title';
import Typography from '@mui/material/Typography';

interface TokenContainerProps {
  title: string;
  text: string;
}

const TokenContainer: React.FC<TokenContainerProps> = (props) => {
  const { title, text } = props;
  const theme = useTheme();

  const typographyStyle = {
    maxWidth: '100%',
    wordWrap: 'break-word',
  };

  return (
    <React.Fragment>
      <Title>{title}</Title>
      <Typography sx={typographyStyle}>{text}</Typography>
    </React.Fragment>
  );
};

export default TokenContainer;
