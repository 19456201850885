import axios from 'axios';
import {
  IAuthTokens,
  TokenRefreshRequest,
  applyAuthTokenInterceptor,
} from 'axios-jwt';

const axiosBackend = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL,
});

export interface IAuthResponse {
  access: string;
  refresh: string;
}

const refreshEndpoint = `${axiosBackend.defaults.baseURL}/token/refresh/`;

export const authResponseToAuthTokens = (res: IAuthResponse): IAuthTokens => ({
  accessToken: res.access,
  refreshToken: res.refresh,
});

const requestRefresh: TokenRefreshRequest = async (
  refreshToken: string
): Promise<string> => {
  return (await axios.post(refreshEndpoint, { refresh: refreshToken })).data
    .access;
};

//applyAuthTokenInterceptor(axiosBackend, { requestRefresh });

axiosBackend.defaults.headers.post['content-type'] = 'application/json';

export default axiosBackend;
