
import axios, { AxiosResponse } from 'axios';

import authHeader from './auth-header';
import { Mention } from '../interface/mention';

const axiosBackend = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL,
});

class MentionService {
  getMentions() {
    return axios.get(axiosBackend.defaults.baseURL + 'mentions/', {
      headers: authHeader(),
    });
  }

  getMention(id: string) {
    return axios.get(axiosBackend.defaults.baseURL + 'mentions/' + id, {
      headers: authHeader(),
    });
  }

}

export default new MentionService();
