import axios, { AxiosResponse } from 'axios';

import authHeader from './auth-header';
import { Task } from '../interface/task';

const axiosBackend = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL,
});

class TaskService {
  getTasks() {
    return axios.get(axiosBackend.defaults.baseURL + 'tasks/', {
      headers: authHeader(),
    });
  }

  getTask(id: string) {
    return axios.get(axiosBackend.defaults.baseURL + 'tasks/' + id, {
      headers: authHeader(),
    });
  }
  getMissions(id: string) {
    return axios.get(
      axiosBackend.defaults.baseURL + 'missions/?task_id=' + id,
      {
        headers: authHeader(),
      }
    );
  }

  async postTask(task: Task): Promise<any> {
    try {
      const response: AxiosResponse = await axios.post(
        axiosBackend.defaults.baseURL + 'tasks/',
        task,
        {
          headers: authHeader(),
        }
      );

      console.log('API response:', response.data);
      return response.data;
    } catch (error) {
      console.error('API error:', error);
      throw error;
    }
  }
}

export default new TaskService();
