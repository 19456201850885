import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import IssueList from './IssueList';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import IssueInput from './IssueInput';
import { ProcessType } from '../interface/process';
import ProcessService from '../services/process.service';

const IssueAdd = () => {
  const location = useLocation();
  const [processData, setProcessData] = useState<ProcessType>();

  const extractUUIDFromURL = (): string | null => {
    const segments = location.pathname.split('/');
    const uuidIndex = segments.length - 2;

    if (uuidIndex >= 0) {
      const uuid = segments[uuidIndex];
      return uuid;
    }

    return null;
  };

  const fetchProcces = (id: string) => {
    ProcessService.getProcess(id)
      .then((response) => {
        console.log(response.data);
        setProcessData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const id = extractUUIDFromURL();
    if (id) {
      fetchProcces(id);
    }
  }, [location]);
  return (
    <Grid container spacing={3}>
      {processData ? (
        <Grid item xs={12}>
          <IssueInput process={processData}></IssueInput>
        </Grid>
      ) : (
        <Paper>No Proc</Paper>
      )}
    </Grid>
  );
};

export default IssueAdd;
