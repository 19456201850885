import { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/system';
import axiosBackend from '../services/axios-backend';
import { useLocation } from 'react-router-dom';
import { Grid, CircularProgress, TextField, Typography } from '@mui/material';
import { Paper } from '@mui/material';
import { SendOutlined } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';

import { useNavigate, useParams } from 'react-router-dom';
import MarkdownDisplay from './MarkdownDisplay';
import DocumentEditor from './DocumentEditor';
import DocumentPanel from './DocumentPanel';
import PlusDocumentIssue from './PlusDocumentIssue';
import { DocumentType } from '../interface/document';
import DocumentService from '../services/document.service';
import Title from './Title';

type DocumentTagProps = {
  icon?: React.ReactNode;
  color: string;
  text: string;
};

export type DocumentProps = {};

const ScrollableContainer = styled(Paper)`
  height: 440px;
  overflow-y: auto;

  /* Hide the scrollbar */
  &::-webkit-scrollbar {
    width: 0.5em;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`;

const Document: React.FC<DocumentProps> = () => {
  const navigate = useNavigate();
  const { documentId: documentId } = useParams<{ documentId: string }>();
  const location = useLocation();
  const sourceId = location.pathname.split('/').pop() as string;

  console.log(useParams());
  const [document, setDocument] = useState<DocumentType | undefined>(undefined);
  const [documentText, setDocumentText] = useState<string>('');
  const [documentWebSocket, setDocumentWebSocket] = useState<
    undefined | WebSocket
  >(undefined);

  const [markdownValue, setMarkdownValue] = useState<string>('');

  const handleMarkdownChange = (value: string) => {
    if (documentId) {
      DocumentService.updateDocument(documentId, value);
    }
    //setMarkdownValue(value);
  };

  const runDocumentWebSocket = () => {
    console.log(
      `${process.env.REACT_APP_BACKEND_WEBSOCKET_URL}${document!.id}/`
    );
    const tempWebSocket = new WebSocket(
      `${process.env.REACT_APP_BACKEND_WEBSOCKET_URL}${document!.id}/`
    );

    tempWebSocket.onclose = () => {
      console.error('Document WebSocket closed unexpectedly');
    };

    tempWebSocket.onmessage = (e) => {
      const data = JSON.parse(e.data);
      const username = data.username;
      const text = data.message;
    };

    setDocumentWebSocket(tempWebSocket);
  };

  const sendMessage = (event?: React.FormEvent<EventTarget>) => {
    if (event) {
      event.preventDefault();
    }

    if (documentText.length === 0) {
      console.error('Message cannot be empty.');
    } else if (documentText.length > 100) {
      console.error(
        'Message is too long. It must be not longer than 100 characters.'
      );
    } else {
      documentWebSocket?.send(
        JSON.stringify({
          username: localStorage.getItem('loggedUserUsername'),
          message: documentText,
        })
      );
      setDocumentText('');
    }
  };

  const fetchDocument = () => {
    axiosBackend
      .get(`/documents/${documentId}`)
      .then((r) => {
        /*
        if (r.data.active === false) {
          console.error(`Document ID ${documentId} is NOT active.`);
          navigate('/');
        }
        */
        setDocument(r.data);
      })
      .catch((e) => {
        console.log(e);
        console.error(`Unable to fetch channel ID ${documentId}.`);
        navigate('/home');
      });
  };

  const isUserDocumentParticipant = (): boolean => {
    return true;
    /*
    return document!.users.includes(
      localStorage.getItem('loggedUserUsername')!
    );
    */
  };

  useEffect(() => {
    if (!document) {
      fetchDocument();
    } else {
      if (!isUserDocumentParticipant()) {
        console.error('You are not a authorized !');
        navigate(`/documents/${document.id}/subscribe`);
      }
      //runDocumentWebSocket();
    }
    return () => {};
  }, [document, navigate]);

  return (
    <Grid container spacing={1}>
      {document ? (
        <Grid item xs={12} md={12} lg={12}>
          <DocumentPanel document={document}></DocumentPanel>
        </Grid>
      ) : (
        <Paper>No Document</Paper>
      )}

      <PlusDocumentIssue></PlusDocumentIssue>
      <Grid item xs={12} md={12} lg={12}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {document?.name ? (
            /*
            <MarkdownDisplay fileUrl={document.file}></MarkdownDisplay>
            */

            <DocumentEditor
              url={document.file}
              document={document.id}
              initialValue=''
              onChange={handleMarkdownChange}
            />
          ) : (
            <CircularProgress />
          )}
        
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Document;
