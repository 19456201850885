import React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from './Title';
import { useTheme } from '@mui/material/styles';

interface AccountProps {
  title: string;
  id: number;
  email: string;
  username: string;
  first_name: string;
  last_name: string;
}

const Account: React.FC<AccountProps> = (props) => {
  const { title, id, email, username, first_name, last_name } = props;

  const theme = useTheme();

  const typographyStyle = {
    maxWidth: '100%',
    wordWrap: 'break-word',
  };

  function preventDefault(event: React.MouseEvent) {
    event.preventDefault();
  }

  return (
    <React.Fragment>
      <Title>{title}</Title>
      <Typography sx={typographyStyle}>
        {first_name} {last_name}
      </Typography>
      <Typography sx={typographyStyle}>{id}</Typography>

      <Typography color='text.secondary' sx={{ flex: 1 }}>
        {email}
      </Typography>
      <div>
        <Link color='primary' href='#' onClick={preventDefault}>
          {username}
        </Link>
      </div>
    </React.Fragment>
  );
};

export default Account;
