import axios, { AxiosResponse } from 'axios';

import authHeader from './auth-header';
import { Stamp } from '../interface/stamp';

const axiosBackend = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL,
});

class StampService {
  getStamps() {
    return axios.get(axiosBackend.defaults.baseURL + 'stamps/', {
      headers: authHeader(),
    });
  }

  getStamp(id: string) {
    return axios.get(axiosBackend.defaults.baseURL + 'stamps/' + id, {
      headers: authHeader(),
    });
  }
}

export default new StampService();
