import { Routes, Route, Navigate } from 'react-router';
import Content from './components/Content';
import Home from './components/Home';
import Login from './components/Login';
import Profile from './components/Profile';
import Reports from './components/Reports';
import Processes from './components/Processes';
import Issues from './components/Issues';
import Issue from './components/Issue';
import ProtectedRoutes from './components/ProtectedRoutes';
import PublicRoutes from './components/PublicRoutes';
import PermissionDenied from './components/PermissionDenied';
import JWTDecodeComponent from './components/JWTDecode';
import ProcessIssues from './components/ProcessIssues';
import IssueAdd from './components/IssuesAdd';
import ProcessInForm from './components/ProcessInForm';
import Footer from './components/Footer';
import Documents from './components/Documents';
import Document from './components/Document';
import MissionList from './components/MissionList';
import TaskList from './components/TaskList';
import TaskDetail from './components/TaskDetail';
import DocumentIssueAdd from './components/DocumentIssuesAdd';
import MissionDetail from './components/MissionDetail';
import Notifications from './components/Notifications';
import Preferences from './components/Preferences';
import AuditLogList from './components/AuditLogList';
import CronTab from './components/CronTab';

import ServiceList from './components/ServiceList';
import ServiceDetail from './components/ServiceDetail';

import MentionList from './components/MentionList';
import MentionDetail from './components/MentionDetail';

import SignatureList from './components/SignatureList';
import SignatureDetail from './components/SignatureDetail';

import StampList from './components/StampList';
import StampDetail from './components/StampDetail';
import AssetList from './components/AssetList';
import AssetDetail from './components/AssetDetail';

const Routing = () => (
  <Routes>
    <Route path='/' element={<ProtectedRoutes />}>
      <Route path='/' element={<Content />}>
        <Route path='/' element={<Navigate replace to='home' />} />
        <Route path='home' element={<Home />} />
        <Route path='preferences' element={<Preferences />} />
        <Route path='profile' element={<Profile />} />
        <Route path='reports' element={<Reports />} />
        <Route path='processes' element={<Processes />} />
        <Route path='processes/new' element={<ProcessInForm />} />
        <Route path='processes/:processId' element={<ProcessIssues />} />
        <Route path='issues' element={<Issues />} />
        <Route path='issues/:issueId' element={<Issue />} />
        <Route path='issues/:processId/add' element={<IssueAdd />} />
        <Route path='documents' element={<Documents />} />
        <Route path='documents/:documentId' element={<Document />} />
        <Route
          path='documents/:documentId/add'
          element={<DocumentIssueAdd />}
        />
        <Route path='documents/:documentId/issues' element={<Issues />} />
        <Route path='notifications' element={<Notifications />} />
        <Route path='missions' element={<MissionList />} />
        <Route path='missions/:missionId' element={<MissionDetail />} />
        <Route path='tasks' element={<TaskList />} />
        <Route path='tasks/:taskId' element={<TaskDetail />} />
        <Route path='audit' element={<AuditLogList />} />
        <Route path='crontab' element={<CronTab />} />

        <Route path='services' element={<ServiceList />} />
        <Route path='services/:serviceId' element={<ServiceDetail />} />

        <Route path='mentions' element={<MentionList />} />
        <Route path='mentions/:mentionId' element={<MentionDetail />} />

        <Route path='signatures' element={<SignatureList />} />
        <Route path='signatures/:signtatureId' element={<SignatureDetail />} />

        <Route path='stamps' element={<StampList />} />
        <Route path='stamps/:stampId' element={<StampDetail />} />

        <Route path='assets' element={<AssetList />} />
        <Route path='assets/:assetId' element={<AssetDetail />} />

        <Route
          path='jwt'
          element={
            <JWTDecodeComponent jwtKey='35b7746e6bc1408bb1c6735f03b9ffbd' />
          }
        />
      </Route>
    </Route>

    <Route path='login' element={<PublicRoutes />}>
      <Route path='/login' element={<Login />} />
    </Route>

    <Route path='/denied' element={<PermissionDenied />} />
  </Routes>
);

export default Routing;
