import axios from 'axios';
import authHeader from './auth-header';

import { ProcessInputType, IProcess, ProcessType } from '../interface/process';

const axiosBackend = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL,
});

class ProcessService {
  getProcesses(issue: string) {
    return axios.get(axiosBackend.defaults.baseURL + 'processes/' + issue, {
      headers: authHeader(),
    });
  }

  getProcess(process: string) {
    return axios.get(axiosBackend.defaults.baseURL + 'processes/' + process, {
      headers: authHeader(),
    });
  }

  getIssues(process: string) {
    return axios.get(
      axiosBackend.defaults.baseURL + 'issues/?process_id=' + process,
      {
        headers: authHeader(),
      }
    );
  }

  postProcess(process: ProcessInputType) {
    return axios
      .post(axiosBackend.defaults.baseURL + 'processes/', process, {
        headers: authHeader(),
      })
      .then((response) => {
        console.log('API response:', response.data);
      })
      .catch((error) => {
        console.error('API error:', error);
      });
  }

  async postNewProcess(process: ProcessInputType): Promise<any> {
    try {
      const response = await axios.post(`${axiosBackend.defaults.baseURL}processes/`, process, {
        headers: authHeader(),
      });
      
      console.log('API response:', response.data);
      return response.data;
    } catch (error) {
      console.error('API error:', error);
      throw error;
    }
  }

  signProcess(process: ProcessType) {
    return axios
      .get(axiosBackend.defaults.baseURL + `processes/${process.id}/sign`, {
        headers: authHeader(),
      })
      .then((response) => {
        console.log('API response:', response.data);
      })
      .catch((error) => {
        console.error('API error:', error);
      });
  }

  markProcessAsSolution(pid: string, txt: string, sol: boolean) {
    const proc = {
      id: pid,
      text: txt,
      solution: sol,
    };
    return axios
      .put(axiosBackend.defaults.baseURL + `processes/${pid}/`, proc, {
        headers: authHeader(),
      })
      .then((response) => {
        console.log('API response:', response.data);
      })
      .catch((error) => {
        console.error('API error:', error);
      });
  }
}

export default new ProcessService();
