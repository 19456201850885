import React, { useEffect, useState } from 'react';
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';
import { Mention} from '../interface/mention';
import MentionService from '../services/mention.service'
import { Link as RouterLink } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';
import Title from './Title';

interface User {
  id: string;
  username: string;
}

const MentionList: React.FC = () => {
  const [mentions, setMentions] = useState<Mention[]>([]);

  const fetchMentions = () => {
    MentionService.getMentions()
      .then((response) => {
        setMentions(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchMentions();
  }, []);

  return (
    <Box>
      <List>
        {mentions.map((s) => (
          <Box
            key={s.id}
            p={2}
            border='1px solid #ccc'
            borderRadius='4px'
            my={2}
            sx={{ backgroundColor: 'white' }}
          >
            <MuiLink
              color='primary'
              component={RouterLink}
              to={`/mentions/${s.id}`}
              underline='none'
            >
              <Title>{s.id}</Title>
            </MuiLink>

            <ListItemText primary={`ID: ${s.id}`} />
            <ListItemText primary={`User: ${s.user}`} />
            <ListItemText primary={`Count: ${s.mention_count}`} />
        </Box>
        ))}
      </List>
    </Box>
  );
};

export default MentionList;
