import axios from 'axios';
import authHeader from './auth-header';
import authMultipartHeader from './auth-header';

const axiosBackend = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL,
});

class AssetService {
  getAssets() {
    return axios.get(axiosBackend.defaults.baseURL + 'assets', {
      headers: authHeader(),
    });
  }

  getAssetsForProcess(pid: string) {
    return axios.get(
      axiosBackend.defaults.baseURL + 'assets/?process_id=' + pid,
      {
        headers: authHeader(),
      }
    );
  }

  postRawAsset(process: string, data: File, txt: boolean) {
    const formData = new FormData();
    formData.append('file', data);
    formData.append('process', process);
    if (txt) {
      formData.append('master', 'true');
    } else {
      formData.append('master', 'false');
    }
    let authentication = authMultipartHeader();
    return axios
      .post(axiosBackend.defaults.baseURL + 'assets/', formData, {
        headers: authentication,
      })
      .then((response) => {
        console.log('API response:', response.data);
      })
      .catch((error) => {
        console.error('API error:', error);
      });
  }

  getAsset(id: string) {
    return axios.get(axiosBackend.defaults.baseURL + 'assets/' + id, {
      headers: authHeader(),
    });
  }

  postAsset(process: string, data: string, txt: boolean) {
    const formData = new FormData();
    const file = new File([data], process + '.json', {
      type: 'application/json',
    });
    formData.append('file', file);
    formData.append('process', process);
    formData.append('master', 'true');
    let authentication = authMultipartHeader();
    return axios
      .post(axiosBackend.defaults.baseURL + 'assets/', formData, {
        headers: authentication,
      })
      .then((response) => {
        console.log('API response:', response.data);
      })
      .catch((error) => {
        console.error('API error:', error);
      });
  }
}

export default new AssetService();
