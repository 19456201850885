import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';

import DocumentService from '../services/document.service';

import MarkdownDisplay from './MarkdownDisplay';
import { DocumentProps } from '../interface/document';


const Documents = (): JSX.Element => {
  const theme = useTheme();

  const [documents, setDocuments] = useState<DocumentProps[]>([]);

  const fetchDocuments = () => {
    DocumentService.getDocuments()
      .then((response) => {
        setDocuments(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  return (
    <Container>
      <Grid container spacing={4}>
        {documents.map((item, i) => (
          <Grid item xs={12} sm={6} key={i}>
            <Box
              component={Card}
              padding={4}
              width={1}
              height={1}
              bgcolor={theme.palette.background.paper}
              sx={{
                '&:hover': {
                  bgcolor: theme.palette.background.default,
                  color:
                    theme.palette.mode === 'dark'
                      ? theme.palette.common.white
                      : theme.palette.common.black,
                },
              }}
            >
              <Box display='flex' flexDirection='column'>
                <MuiLink
                  color='primary'
                  component={RouterLink}
                  to={`/documents/${item.id}`}
                  underline='none'
                >
                  <Typography
                    variant='h6'
                    gutterBottom
                    sx={{
                      fontWeight: 600,
                    }}
                  >
                    {item.name}
                  </Typography>
                </MuiLink>
                <Typography color='inherit'>{item.description}</Typography>
              </Box>
              <Box display='block' width={1} height={1}>
                <Card
                  sx={{
                    width: 1,
                    height: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: 'none',
                    bgcolor: 'transparent',
                    backgroundImage: 'none',
                  }}
                >
                  <CardMedia
                    title=''
                    image={item.file}
                    sx={{
                      position: 'relative',
                      height: 320,
                      overflow: 'hidden',
                      borderRadius: 2,
                      filter:
                        theme.palette.mode === 'dark'
                          ? 'brightness(0.7)'
                          : 'brightness(0.9)',
                      mt: 4,
                    }}
                  >
                    <MarkdownDisplay fileUrl={item.file}></MarkdownDisplay>
                  </CardMedia>
                </Card>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Documents;
