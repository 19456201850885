import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Paper,
  List,
  ListItemText,
  ListItem,
  Typography,
  Link as MuiLink,
  Card,
  CardContent,
} from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import { Link as RouterLink } from 'react-router-dom';
import Title from './Title';
import { AuditLog, AuditLogEntry, ChangeValues } from '../interface/auditlog';
import AuditService from '../services/audit.service';
import ServiceTag from './ServiceTag';

interface AuditLogListProps {}

const AuditLogList: React.FC<AuditLogListProps> = () => {
  const [entries, setEntries] = useState<AuditLogEntry[]>([]);

  const convertChanges = (changes: string): { [key: string]: ChangeValues } => {
    const unescapedChanges = JSON.parse(changes.replace(/\\"/g, '"'));
    const convertedChanges: { [key: string]: ChangeValues } = {};

    for (const key in unescapedChanges) {
      const [before, after] = unescapedChanges[key];
      const parsedBefore = before !== 'None' ? before : null;
      const parsedAfter = after !== 'None' ? after : null;

      convertedChanges[key] = {
        before: parsedBefore,
        after: parsedAfter,
      };
    }
    return convertedChanges;
  };

  const fetchAuditLogEntries = () => {
    AuditService.getAuditEntries()
      .then((response) => {
        const convertedEntries = response.data.results.map(
          (entry: AuditLog) => ({
            ...entry,
            changes: convertChanges(entry.changes),
          })
        );
        setEntries(convertedEntries);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchAuditLogEntries();
  }, []);

  return (
    <Box>
      <List>
        {entries.map((log) => (
          <Box
            key={log.id}
            p={2}
            border='1px solid #ccc'
            borderRadius='4px'
            my={2}
            sx={{ backgroundColor: 'white' }}
          >
            <MuiLink
              color='primary'
              component={RouterLink}
              to={`/audit/${log.id}`}
              underline='none'
            >
              <Title>{log.actor}</Title>
            </MuiLink>
            <ListItemText primary={`ID: ${log.id}`} />
            <ListItemText primary={`Timestamp: ${log.timestamp}`} />
            <ListItemText primary={`Actor: ${log.actor}`} />
            <ListItemText primary={`Action: ${log.action}`} />
            <ListItemText primary={`Object PK: ${log.object_pk}`} />
            <ListItem>
              <ServiceTag id={log.content_type}></ServiceTag>
              </ListItem>
            <ListItem>
              <Grid
                container
                spacing={2}
                justifyContent='left'
                alignItems='left'
              >
                <Grid item key={log.object_pk} xs={12} sm={12} md={12}>
                  {Object.keys(log.changes).map((field) => {
                    const { before, after } = log.changes[field];
                    return (
                      <Box key={field} m={1}>
                        <Card sx={{ minWidth: 100, marginRight: 1 }}>
                          <CardHeader title={field} />

                          <CardContent>
                            <Grid
                              container
                              spacing={2}
                              justifyContent='left'
                              alignItems='left'
                            >
                              <Grid item key={log.object_pk} xs={6} sm={6} md={6}>
                                <Typography variant="body2" component="span" sx={{ backgroundColor: 'primary.dark', p: 1 }} color="white">
                                  {before ? before : 'None'}
                                  </Typography>
                              </Grid>
                              <Grid item key={log.object_id} xs={6} sm={6} md={6}>
                                <Typography variant="body2" component="span" sx={{ backgroundColor: 'secondary.dark', p: 1 }} color="white">
                                  {after ? after: 'None'}
                                </Typography>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Box>
                    );
                  })}
                </Grid>
              </Grid>
            </ListItem>
          </Box>
        ))}
      </List>
    </Box>
  );
};

export default AuditLogList;
