import React, { useEffect, useState } from 'react';
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';
import { Signature } from '../interface/signature';
import SignatureService from '../services/signature.service';
import { Link as RouterLink } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';
import Title from './Title';

interface User {
  id: string;
  username: string;
}

const SignatureList: React.FC = () => {
  const [signatures, setSignatures] = useState<Signature[]>([]);

  const fetchSignatures = () => {
    SignatureService.getSignatures()
      .then((response) => {
        setSignatures(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchSignatures();
  }, []);

  return (
    <Box>
      <List>
        {signatures.map((s) => (
          <Box
            key={s.id}
            p={2}
            border='1px solid #ccc'
            borderRadius='4px'
            my={2}
            sx={{ backgroundColor: 'white' }}
          >
            <MuiLink
              color='primary'
              component={RouterLink}
              to={`/signatures/${s.id}`}
              underline='none'
            >
              <Title>{s.user}</Title>
            </MuiLink>

            <ListItemText primary={`ID: ${s.id}`} />
            <ListItemText primary={`Timestamp: ${s.timestamp}`} />
          </Box>
        ))}
      </List>
    </Box>
  );
};

export default SignatureList;
