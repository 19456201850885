import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ShareIcon from '@mui/icons-material/Share';
import ForkRightIcon from '@mui/icons-material/ForkRight';
import VerifiedIcon from '@mui/icons-material/Verified';
import NoteIcon from '@mui/icons-material/Note';
import { ProcessType } from '../interface/process';
import { DocumentType } from '../interface/document';
import { IssueInputType } from '../interface/issue';
import SendIcon from '@mui/icons-material/Send';
import IssueService from '../services/issue.service';
import { useNavigate } from 'react-router-dom';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import GroupIcon from '@mui/icons-material/Group';
import ScheduleIcon from '@mui/icons-material/Schedule';

interface IssueInputProps {
  process: ProcessType;
  document?: DocumentType;
}
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const handleAdminClick = () => {
  console.log('Admin button clicked');
};

const handleShareClick = () => {
  console.log('Share button clicked');
};

const handleNoteClick = () => {
  console.log('Note button clicked');
};

const handleVerifiedClick = () => {
  console.log('Verified button clicked');
};

const handleForkRightClick = () => {
  console.log('Fork Right button clicked');
};

export default function IssueInput(props: IssueInputProps) {
  const { process } = props;
  const navigate = useNavigate();

  const [inputValue, setInputValue] = React.useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13) {
      handleSendClick(inputValue);
    }
  };

  const handleSendClick = async (value: string) => {
    const issue: IssueInputType = {
      process: process.id,
      name: value,
      constraints: {},
      timestamp: new Date(),
    };
    const data = await IssueService.postIssue(issue);
    setInputValue('');
    navigate(`/issues/${data.id}`);
  };

  return (
    <Card>
      <CardContent>
        <TextField
          fullWidth
          placeholder='Add Issue'
          variant='outlined'
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          InputProps={{
            disableUnderline: true,
            sx: { fontSize: 'default' },
          }}
        />
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label='Admin' onClick={handleAdminClick}>
          <AdminPanelSettingsIcon></AdminPanelSettingsIcon>
        </IconButton>
        <IconButton aria-label='Group' onClick={handleShareClick}>
          <GroupIcon></GroupIcon>
        </IconButton>
        <IconButton aria-label='Note' onClick={handleNoteClick}>
          <NoteIcon></NoteIcon>
        </IconButton>
        <IconButton aria-label='Schedule' onClick={handleForkRightClick}>
          <ScheduleIcon></ScheduleIcon>
        </IconButton>
        <IconButton aria-label='Verified' onClick={handleVerifiedClick}>
          <VerifiedIcon></VerifiedIcon>
        </IconButton>
        <ExpandMore
          expand={true}
          onClick={() => handleSendClick(inputValue)}
          aria-expanded={true}
          aria-label='Send'
        >
          <SendIcon></SendIcon>
        </ExpandMore>
      </CardActions>
    </Card>
  );
}
