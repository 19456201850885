import React from 'react';
import { PluginProps } from 'react-markdown-editor-lite';
import IssueService from '../services/issue.service';
import { DocumentIssueInputType } from '../interface/document';
import IconButton from '@mui/material/IconButton';
import ForkRightIcon from '@mui/icons-material/ForkRight';

interface EditorIssueState {
  num: number;
  document: string;
}

const styles = {
  iconButton: {
    fontSize: '1.2rem', // Adjust this value to change the icon size
  },
  forkIcon: {
    fontSize: '0.9rem', // Adjust this value to change the icon size
  },
};

export const EditorIssue = (props: PluginProps) => {
  const [num, setNum] = React.useState(props.config.start);
  const [document, setDocument] = React.useState(props.config.document);

  const handleClick = async () => {
    const selection = props.editor.getSelection();
    //const newIssue = IssueService.postDocumentIssue();
    console.log(document);
    const issue: DocumentIssueInputType = {
      document: document,
      name: selection.text,
      constraints: {},
      timestamp: new Date(),
    };
    console.log(selection.text);
    console.log(issue);
    const data = await IssueService.postDocumentIssue(issue);
    const dest = `/issues/${data.id}`;
    console.log(dest);
    console.log(data);
    const linkText = `[${selection.text}](${dest})`;
    if (selection && selection.start !== selection.end) {
      const content = props.editor.getMdValue();
      const updatedContent =
        content.slice(0, selection.start) +
        linkText +
        content.slice(selection.end);

      props.editor.setText(updatedContent);
    } else {
      props.editor.insertText(linkText);
    }

    setNum(num + 1);
  };

  return (
    <IconButton aria-label='Schedule' onClick={handleClick}>
      <ForkRightIcon style={styles.forkIcon} />
    </IconButton>
  );
};

EditorIssue.defaultConfig = {
  start: 0,
};
EditorIssue.align = 'left';
EditorIssue.pluginName = 'hercules-issue-create';
