import React, { useEffect, useState } from 'react';
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';
import { Service} from '../interface/service';
import ServiceService from '../services/service.service';
import { Link as RouterLink } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';
import Title from './Title';

interface User {
  id: string;
  username: string;
}

const ServiceList: React.FC = () => {
  const [services, setServices] = useState<Service[]>([]);

  const fetchServices = () => {
    ServiceService.getServices()
      .then((response) => {
        setServices(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchServices();
  }, []);

  return (
    <Box>
      <List>
        {services.map((s) => (
          <Box
            key={s.id}
            p={2}
            border='1px solid #ccc'
            borderRadius='4px'
            my={2}
            sx={{ backgroundColor: 'white' }}
          >
            <MuiLink
              color='primary'
              component={RouterLink}
              to={`/services/${s.id}`}
              underline='none'
            >
              <Title>{s.service}</Title>
            </MuiLink>

            <ListItemText primary={`ID: ${s.id}`} />
            <ListItemText primary={`Name: ${s.service}`} />
        </Box>
        ))}
      </List>
    </Box>
  );
};

export default ServiceList;
