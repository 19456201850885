import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import Title from './Title';
import { Link as RouterLink } from 'react-router-dom';
import { Link as MuiLink, Typography } from '@mui/material';

import IssueService from '../services/issue.service';
import { ProcessType, ProcessesType } from '../interface/process';
import { IssuesType } from '../interface/issue';
import ProcessService from '../services/process.service';
import IssueInput from './IssueInput';
import Solutionis from './Solutions';
import Process from './Process';

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

export default function ProcessIssues() {
  const location = useLocation();
  const [processData, setProcessData] = useState<ProcessType>();
  const [issues, setIssues] = useState<IssuesType>([]);
  const [processId, setProcessId] = useState<string>('');

  const fetchProcess = (id: string) => {
    ProcessService.getProcess(id)
      .then((response) => {
        setProcessData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchIssues = (id: string) => {
    ProcessService.getIssues(id)
      .then((response) => {
        console.log(response.data);
        setIssues(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const id = location.pathname.split('/').pop();
    if (id) {
      setProcessId(id);
      fetchProcess(id);
      fetchIssues(id);
    }
  }, [location]);

  return (
    <Grid container spacing={3}>
      {processData ? (
        <>
          <Grid item xs={12} sm={12} md={12}>
            <Process process={processData}></Process>
          </Grid>
          <Grid item key={processData?.id} xs={12} sm={12} md={12}>
            <IssueInput process={processData}></IssueInput>
          </Grid>
        </>
      ) : (
        <Typography>No Process</Typography>
      )}
      {issues.map((issue) => (
        <Grid item key={issue.id} xs={12} sm={12} md={12}>
          <Card>
            <CardContent>
              <MuiLink
                color='primary'
                component={RouterLink}
                to={`/issues/${issue.id}`}
                underline='none'
              >
                <Title>{issue.name}</Title>
              </MuiLink>
              <Typography color='textSecondary' gutterBottom>
                URL: {issue.url}
              </Typography>
              <Typography color='textSecondary' gutterBottom>
                Active: {issue.active ? 'Yes' : 'No'}
              </Typography>
              <Typography color='textSecondary' gutterBottom>
                Creator: {issue.creator}
              </Typography>
              <Typography color='textSecondary' gutterBottom>
                Timestamp: {issue.timestamp}
              </Typography>
              <Typography color='textSecondary' gutterBottom>
                Admins: {issue.admins.join(', ')}
              </Typography>
              <Typography color='textSecondary' gutterBottom>
                Users: {issue.users.join(', ')}
              </Typography>
            </CardContent>
            <Solutionis issue={issue}></Solutionis>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
